import axios from 'axios'
import _ from 'lodash'

const initState = {
  currentView: {
    isAllBoxes: false,
    isEdit: false,
    shipRegistration: null,
    deliveryOrder: null,
    deliveryOrdersArray: [],
    mapSrc: null,
    data: [],
  },
  objectViews: [],
}

export const state = Object.assign({}, _.cloneDeep(initState))

export const mutations = {
  addObjectView(state, view) {
    const existView = state.objectViews.find((el) => el.viewId === view.viewId)

    if (existView === undefined) {
      state.objectViews.push(view)
    }
  },

  setCurrentViewProperty(state, payload) {
    Object.keys(payload).forEach((key) => {
      state.currentView[key] = payload[key]
    })
  },

  setObjectViewProperty(state, payload) {
    const index = state.objectViews.findIndex((el) => el.viewId === payload.viewId)
    if (index > -1) {
      state.objectViews[index][payload.property] = payload.value
    }
  },

  setObjectProperty(state, payload) {
    const index = state.objectViews.findIndex((el) => el.viewId === payload.viewId)
    if (index > -1) {
      state.objectViews[index].object[payload.property] = payload.value
    }
  },

  delObjectView(state, viewId) {
    for (const [i, v] of state.objectViews.entries()) {
      if (v.viewId === viewId) {
        state.objectViews.splice(i, 1)
        break
      }
    }
  },

  setCurrentView(state, currentView) {
    state.currentView = currentView
  },
  resetState(state) {
    Object.assign(state, _.cloneDeep(initState))
  },
}

export const actions = {
  resetState({ commit }) {
    commit('resetState')
  },
}

export const getters = {
  currentView(state) {
    return state.currentView
  },

  objectView: (state) => (viewId) => {
    return state.objectViews.find((el) => el.viewId === viewId)
  },

  isAllBoxes(state) {
    return state.currentView.isAllBoxes
  },

  isEdit(state) {
    return state.currentView.isEdit
  },

  shipRegistration(state) {
    return state.currentView.shipRegistration
  },

  deliveryOrder(state) {
    return state.currentView.deliveryOrder
  },

  deliveryOrdersArray(state) {
    return state.currentView.deliveryOrdersArray
  },

  mapSrc(state) {
    return state.currentView.mapSrc
  },
  data(state) {
    return state.currentView.data
  },
}
