import axios from 'axios'
import moment from 'moment'
import Interaction from '@/dto/Interaction.json'
import _ from 'lodash'
import Vue from 'vue'

const initState = {
  listView: {
    list: [],
    total: 0,
    page: 1,
    limit: 20,
    settings: {
      scrollTop: null,
    },
    filters: {
      searchStr: '',
      showClosed: false,
      showNoTasks: false,
      status: null,
      multiplyStatus: false,
      manager: null,
      financialPeriod: null,
      executor: null,
      department: null,
      period: [null, null],
      customer: null,
      tags: null,
      multiplyTags: false,
    },
    sort: { sortBy: null, sortDesc: false },
  },
  objectViews: [],
}

export const state = Object.assign({}, _.cloneDeep(initState))

export const mutations = {
  setListViewProperty(state: any, payload: any) {
    Object.keys(payload).forEach((key) => {
      state.listView[key] = payload[key]
    })
  },

  setSettingsProperty(state: any, property: any) {
    Object.keys(property).forEach((key) => {
      state.listView.settings[key] = property[key]
    })
  },

  setFilters(state: any, filter: any) {
    Object.keys(filter).forEach((key) => {
      state.listView.filters[key] = filter[key]
    })
  },

  setSort(state: any, sort: any) {
    Object.keys(sort).forEach((key) => {
      state.listView.sort[key] = sort[key]
    })
  },

  addObjectView(state: any, view: any) {
    const existView = state.objectViews.find((el: any) => el.viewId === view.viewId)
    if (existView === undefined) {
      state.objectViews.push(view)
    }
  },

  setObjectViewProperty(state: any, payload: any) {
    const index = state.objectViews.findIndex((el: any) => el.viewId === payload.viewId)
    if (index > -1) {
      state.objectViews[index][payload.property] = payload.value
    }
  },

  setObjectViewProperties(state: any, payload: any) {
    const index = state.objectViews.findIndex((el: any) => el.viewId === payload.viewId)
    if (index > -1) {
      Object.keys(payload.props).forEach((key) => {
        state.objectViews[index][key] = payload.props[key]
      })
    }
  },

  setObjectProperty(state: any, payload: any) {
    const index = state.objectViews.findIndex((el: any) => el.viewId === payload.viewId)
    if (index > -1) {
      Vue.set(state.objectViews[index].object, payload.property, payload.value)
    }
  },

  delObjectView(state: any, viewId: any) {
    for (const [i, v] of state.objectViews.entries()) {
      if (v.viewId === viewId) {
        state.objectViews.splice(i, 1)
        break
      }
    }
  },

  updateFile(state: any, payload: any) {
    const viewIndex = state.objectViews.findIndex((el: any) => el.viewId === payload.viewId)
    if (viewIndex > -1) {
      const existFile = state.objectViews[viewIndex].object.files.find((el: any) => {
        return el.id === payload.fileId
      })
      if (existFile) {
        existFile[payload.property] = payload.value
      }
    }
  },

  delCommentFile(state: any, payload: any) {
    const viewIndex = state.objectViews.findIndex((el: any) => el.viewId === payload.viewId)
    if (viewIndex > -1) {
      const existComment = state.objectViews[viewIndex].object.comments.find((el: any) => {
        return el.id === payload.commentId
      })
      if (existComment) {
        existComment.files.splice(payload.index, 1)
      }
    }
  },

  delComment(state: any, { viewId, index }: any) {
    const viewIndex = state.objectViews.findIndex((el: any) => el.viewId === viewId)
    if (viewIndex > -1) {
      state.objectViews[viewIndex].object.comments.splice(index, 1)
    }
  },

  setProperties(state: any, payload: any) {
    Object.keys(payload.props).forEach((key) => {
      Vue.set(payload.object, key, payload.props[key])
    })
  },

  resetState(state: any) {
    Object.assign(state, _.cloneDeep(initState))
  },
}

export const actions = {
  async findAll({ commit, state }: any, payload: any) {
    return axios
      .get(`/interactions`, payload)
      .then((response) => {
        let list = []
        let page = 1
        let total = 0

        if (response.status === 200) {
          const result = response.data

          if (result.rows) {
            list = result.rows
          } else {
            list = result
          }

          list.forEach((el: any) => {
            el.createdAt = moment(el.createdAt).format('DD.MM.YYYY HH:mm:ss')
            if (el.sendingDate) {
              el.sendingDate = moment(el.sendingDate).format('DD.MM.YYYY HH:mm:ss')
            }

            el.tags = JSON.parse(el.tags)
          })

          if (result.count) {
            total = result.count
          } else {
            total = list.length
          }

          if (total > 0) {
            const pages = Math.ceil(total / state.listView.limit)

            if (pages < state.listView.page) {
              page = pages
            } else {
              page = state.listView.page
            }
          }
        }

        if (!payload.noCommit) {
          commit('setListViewProperty', { list, page, total })
        }
        return response
      })
      .catch((error) => {
        console.error(error)
        throw error
      })
  },

  async getCount(context: any, payload: any) {
    return axios.get(`/interactions/count`, payload).catch((error) => {
      throw error
    })
  },

  async findByPk({ commit }: any, payload: any) {
    if (!payload.noCommit && !payload.viewId) {
      if (state.objectViews.some((v: any) => v.viewId === payload.params.id)) {
        return { status: 200 }
      }
    }

    return axios
      .get(`/interactions/${payload.params.id}`, payload.query)
      .then((response) => {
        if (!payload.noCommit && response.status === 200) {
          const object = response.data
          object.createdAt = moment(object.createdAt).format('DD.MM.YYYY HH:mm:ss')
          if (payload.viewId) {
            commit('setObjectViewProperty', { viewId: payload.viewId, property: 'object', value: object })
          } else {
            commit('addObjectView', {
              viewId: payload.params.id,
              object,
              editMode: 'EDIT',
            })
          }
        }

        return response
      })
      .catch((error) => {
        console.error(error)
        throw error
      })
  },

  async getVersions({ commit }: any, payload: any) {
    return axios.get(`/interactions`, payload).then((response) => {
      if (response.status === 200) {
        response.data.forEach((el: any) => {
          el.createdAt = moment(el.createdAt).format('DD.MM.YYYY HH:mm:ss')
        })
      }
      return response
    })
  },

  async addNew({ commit, dispatch }: any, payload: any) {
    const newObject = _.cloneDeep(Interaction)

    newObject.id = payload.viewId ? payload.viewId : payload

    if (payload.byEmail === true && payload.emailData) {
      if (Array.isArray(payload.emailData)) {
        newObject.emailTitle = payload.emailData
          .map((el: any) => {
            return `${el.title} (${el.from})`
          })
          .join(', ')

        for (const email of payload.emailData) {
          if (email.counterpartyId) {
            newObject.customerId = email.counterpartyId

            await dispatch('counterparties/findByPk', {
              noCommit: true,
              params: {
                id: email.counterpartyId,
              },
            })
              .then((response: any) => {
                if (response.status === 200) {
                  newObject.customer = response.data
                }
              })
              .catch((error: any) => {
                console.error(error)
              })
            break
          }
        }

        newObject.files = []
        for (const email of payload.emailData) {
          if (email.filesAtHardDrive === true) {
            let emailFiles = email.files

            if (!emailFiles) {
              await dispatch('incomingEmails/getFiles', { params: { emailId: email.id } }, { root: true })
                .then(async (response: any) => {
                  if (response.status === 200) {
                    emailFiles = response.data
                  }
                })
                .catch((error: any) => {
                  console.error(error)
                })
            }

            if (emailFiles) {
              for (const file of emailFiles) {
                if (file.contentDisposition === 'attachment' || file.contentType === 'application/pdf') {
                  await dispatch('incomingEmails/getFile', { id: file.id }, { root: true })
                    .then((response: any) => {
                      if (response.status === 200) {
                        const itemFile = new File([response.data], file.filename, { type: file.contentType })
                        newObject.files.push(itemFile)
                      }
                    })
                    .catch((error: any) => {
                      throw error
                    })
                }
              }
            }
          }
        }
      } else {
        if (payload.emailData.title) {
          newObject.emailTitle = `${payload.emailData.title} (${payload.emailData.from})`
        }

        if (payload.emailData.counterpartyId) {
          newObject.customerId = payload.emailData.counterpartyId
          newObject.customer = payload.emailData.counterparty

          // await dispatch('counterparties/findByPk', {
          //   noCommit: true,
          //   params: {
          //     id: payload.emailData.counterpartyId,
          //   },
          // }).then((response: any) => {
          //   console.log('response', response)
          //   if (response.status === 200) {
          //     newObject.customer = response.data
          //   }
          // }).catch((error: any) => {
          //   console.error(error)
          // })
        }

        if (payload.emailData.filesAtHardDrive === true) {
          newObject.files = []

          let emailFiles = payload.emailData.files

          if (!emailFiles) {
            await dispatch('incomingEmails/getFiles', { params: { emailId: payload.emailData.id } }, { root: true })
              .then(async (response: any) => {
                if (response.status === 200) {
                  emailFiles = response.data
                }
              })
              .catch((error: any) => {
                console.error(error)
              })
          }

          if (emailFiles) {
            for (const file of emailFiles) {
              if (file.contentDisposition === 'attachment' || file.contentType === 'application/pdf') {
                await dispatch('incomingEmails/getFile', { id: file.id }, { root: true })
                  .then((response: any) => {
                    if (response.status === 200) {
                      const itemFile = new File([response.data], file.filename, { type: file.contentType })
                      newObject.files.push(itemFile)
                    }
                  })
                  .catch((error: any) => {
                    throw error
                  })
              }
            }
          }
        }
      }
    }

    commit('addObjectView', {
      viewId: payload.viewId,
      editMode: 'NEW',
      nevVersion: payload.nevVersion,
      byEmail: payload.byEmail,
      emailData: payload.emailData,
      object: newObject,
    })

    return true
  },

  async create({ dispatch, getters }: any, payload: any) {
    return axios
      .post(`/interactions`, payload)
      .then((response) => {
        if (response.status === 200 && payload.objectData?.files.length > 0) {
          const formData = new FormData()

          payload.objectData?.files.map((file: any) => formData.append('files', file))

          formData.append('data', JSON.stringify({ id: response.data.id }))

          return axios
            .post(`/interactions/file`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .then((response) => {
              return response
            })
            .catch((error) => {
              console.error(error)
              throw error
            })
        } else {
          return response
        }
      })
      .catch((error) => {
        throw error
      })
  },

  async update({ commit }: any, payload: any) {
    return axios.put(`/interactions/${payload.id}`, payload.updateData).then((response) => {
      return response
    })
  },

  async updateFile({ commit }: any, payload: any) {
    return axios.put(`/interactions/file/${payload.id}`, payload.updateData).then((response) => {
      return response
    })
  },

  async addComment(context: any, payload: any) {
    const formData = new FormData()
    payload.files.map((file: any) => formData.append('files', file))

    formData.append(
      'data',
      JSON.stringify({
        text: payload.text,
        byEmail: payload.byEmail,
        emailTitle: payload.emailTitle,
        emailUid: payload.emailUid,
        emailId: payload.emailId,
        emailAccountId: payload.emailAccountId,
        emailType: payload.emailType,
        parentId: payload.parentId,
      })
    )
    return axios
      .post(`/interactions/comment`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        return response
      })
  },

  async deleteComment({ commit }: any, payload: any) {
    return axios.delete(`/interactions/comment/${payload.id}`, payload).then((response) => {
      if (response.status === 200) {
        commit('delComment', { viewId: payload.viewId, index: payload.index })
      }
      return response
    })
  },

  async addFiles({ commit }: any, payload: any) {
    const formData = new FormData()
    payload.files.map((file: any) => formData.append('files', file))

    formData.append('data', JSON.stringify({ id: payload.id }))

    return axios
      .post(`/interactions/file`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        return response
      })
  },

  async changeDeletionMark({ commit }: any, payload: any) {
    return axios.post(`/interactions/change_deletion_mark`, { id: payload.id }).then((response) => {
      return response
    })
  },

  async changeHistoryDeletionMark({ commit }: any, payload: any) {
    const path = `/${payload.type === 'event' ? 'events' : 'interactions/comment'}/change_deletion_mark`
    return axios.post(path, { id: payload.value.id }).then((response) => {
      return response
    })
  },

  async uploadFiles({ commit, getters }: any, params: any) {
    const formData = new FormData()

    params.files.map((file: any) => formData.append('files', file))

    formData.append('data', JSON.stringify({ id: params.id }))

    return axios
      .post(`/interactions/file`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.error(error)
        return undefined
      })
  },

  async uploadCommentFiles({ commit, getters }: any, params: any) {
    const formData = new FormData()

    params.files.map((file: any) => formData.append('files', file))

    formData.append('data', JSON.stringify({ id: params.id, parentId: params.parentId }))

    return axios
      .post(`/interactions/comment/file`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.error(error)
        return undefined
      })
  },

  async getFiles({ commit }: any, payload: any) {
    return axios
      .post(`/interactions/files`, { objectId: payload.objectId })
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.error(error)
        return undefined
      })
  },

  async getTasks({ commit }: any, payload: any) {
    return axios
      .post(`/interactions/tasks`, { objectId: payload.objectId })
      .then((response) => {
        return response
      })
      .catch((error) => {
        throw error
      })
  },

  async getCommentFiles({ commit }: any, params: any) {
    return axios
      .get(`/interactions/comment/files`, { objectId: params.objectId } as any)
      .then((response) => {
        // commit('setFiles', response.data)
        return response
      })
      .catch((error) => {
        console.error(error)
        return undefined
      })
  },

  async deleteFile({ commit }: any, params: any) {
    return axios
      .delete(`/interactions/file/${params.id}`)
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.error(error)
        return error
      })
  },

  async deleteCommentFile({ commit }: any, payload: any) {
    return axios
      .delete(`/interactions/comment/file/${payload.id}`)
      .then((response) => {
        if (response.status === 200) {
          commit('delCommentFile', payload)
        }
        return response
      })
      .catch((error) => {
        console.error(error)
        return error
      })
  },

  async openFile({ commit }: any, params: any) {
    return axios
      .get(`/interactions/file/${params.id}`, { responseType: 'blob', params: {} })
      .then((response) => {
        if (response.status === 200) {
          const blob = new Blob([response.data], { type: params.type })
          const fileLink = document.createElement('a')
          fileLink.href = window.URL.createObjectURL(blob)
          if (
            params.open === true &&
            (params.type === 'application/pdf' || params.type === 'image/jpeg' || params.type === 'image/png' || params.type === 'application/vnd.ms-excel')
          ) {
            fileLink.target = '_blank'
            fileLink.rel = 'noopener noreferrer'
          } else {
            fileLink.setAttribute('download', params.name)
          }
          document.body.appendChild(fileLink)
          fileLink.click()
        }
        return response
      })
      .catch((error) => {
        console.error(error)
        return undefined
      })
  },

  async getFile({ commit }: any, params: any) {
    return axios
      .get(`/interactions/file/${params.id}`, { responseType: 'blob', params: {} })
      .then((response) => {
        return response
      })
      .catch((error) => {
        console.error(error)
        return undefined
      })
  },

  async openCommentFile({ commit }: any, params: any) {
    return axios
      .get(`/interactions/comment/file/${params.id}`, { responseType: 'blob', params: {} })
      .then((response) => {
        if (response.status === 200) {
          const blob = new Blob([response.data], { type: params.type })
          const fileLink = document.createElement('a')
          fileLink.href = window.URL.createObjectURL(blob)

          if (
            params.open === true &&
            (params.type === 'application/pdf' || params.type === 'image/jpeg' || params.type === 'image/png' || params.type === 'application/vnd.ms-excel')
          ) {
            fileLink.target = '_blank'
            fileLink.rel = 'noopener noreferrer'
          } else {
            fileLink.setAttribute('download', params.name)
          }

          document.body.appendChild(fileLink)
          fileLink.click()
        }
        return response
      })
      .catch((error) => {
        console.error(error)
        return undefined
      })
  },

  resetState({ commit }: any) {
    commit('resetState')
  },
}

export const getters = {
  listView(state: any) {
    return state.listView
  },

  objectView: (state: any) => (viewId: any) => {
    return state.objectViews.find((el: any) => el.viewId === viewId)
  },

  presentation: (state: any) => (viewId: any) => {
    const objectView = state.objectViews.find((el: any) => el.viewId === viewId)

    let presentation = ''
    if (objectView) {
      presentation = objectView.object.numberStr
    }

    return presentation
  },
}
