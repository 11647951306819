export default {
  route: {
    main: 'Principal',
    dashboard: 'Tableau de bord',
    dashboards: 'Tableaux de bord',
    sales: 'Ventes',
    calendar: 'Calendrier',
    tasks: 'Tâches',
    salesOrders: 'Commandes clients',
    products: 'Produits',
    counterparties: 'Sous-traitens',
    counterparty: 'Prestataire',
    costomers: 'Clients',
    costomer: 'Client',
    carriers: 'Transporteurs',
    carrier: 'Transporteur',
    vendorsAndCustomers: 'Fournisseurs et destinataires',
    reclamations: 'Réclamations',
    reclamationviewform: 'Réclamations (Visualiser)',
    registrationprotocol: "Protocole d'enregistrement",
    reclCatalogs: 'Dictionnaires',
    reclSubjects: 'Objet des commandes',
    statusGroups: 'Groupes de statuts',
    reclStates: 'Statuts des commandes',
    reclPerpetrators: 'Personnes responsable des réclamations',
    reclTypes: 'Types des commandes',
    reclExecutions: 'Réalisation',
    reclReasons: 'Motifs de la réclamation',
    reclDecisions: 'Décisions',
    costArticles: 'Types de coûts',
    costArticle: 'Type de coût',
    deliveryTypes: 'Modes de livraison',
    deliveryType: 'Mode de livraison',
    settings: 'Paramètres',
    firms: 'Entreprises',
    firm: 'Entreprise',
    ships: 'Navires',
    scales: 'Poids',
    event: 'Événement',
    events: 'Événements',
    salesPoints: 'Points de vente',
    users: 'Utilisateurs',
    usersList: "Liste d'utilisateurs",
    accessGroups: "Groupes d'autorisations",
    otherSettings: 'Autres paramètres',
    administration: 'Administration',
    emailTemplates: "Modèles d'e-mails",
    numerators: 'Numéroteurs de documents',
    executors: 'Exécutants de tâches',
    executorRoles: "Rôles d'executeurs",
    exchangeObjects: 'Objets échangeables',
    taskTemplates: 'Modèles de tâches',
    customerRequests: "Demandes d'offres",
    crStatuses: "Statuts de la demande d'offres",
    crStatuse: "Statut de l'offre",
    mailbox: 'Boîtes mail',
    salesOrder: 'Commande clients',
    customerRequest: "Demandes d'offres",
    newEmail: 'Nouveau e-mail',
    emailDetails: 'E-mail',
    outgoingEmailDetails: 'E-mail envoyé',
    task: 'Tâches',
    product: 'Données de position',
    customer: 'Données clients',
    accessGroup: "Groupe d'autorisations",
    user: 'Utilisateurs',
    executor: 'Exécutant',
    executorRole: "Rôles d'executeurs",
    taskTemplate: 'Modèles de tâches',
    reclamation: 'Réclamation',
    reclamationStatistic: 'Statistiques des réclamations',
    emailAccounts: 'Comptes e-mail',
    emailAccount: 'Compte e-mail',
    crTags: "Tags des demandes d'offres",
    crTag: "Tag de la demande d'offre",
    usersAndAccess: 'Utilisateurs et autorisations',
    bpAndTasks: 'Processus et tâches métier',
    bpDefinitions: 'Définition des processus métiers',
    bpDefinition: 'Définition du processus métier',
    employees: 'Employés',
    employee: 'Employé',
    report: 'Rapport',
    reports: 'Rapports',
    salesOrderStatuses: 'Statuts des commandes clients',
    currencies: 'Devises',
    currency: 'Devise',
    countries: 'Pays',
    country: 'Pays',
    termsOfPayment: 'Délais de paiement ',
    pricelists: 'Tarifs de fabrication',
    pricelist: 'Tarif de fabrication',
    discounts: 'Remises sur la productions',
    discount: 'Remises sur la production',
    documentPrefixes: 'Préfixes de documents',
    interactionStatuses: "Statuts d'interaction",
    interactionTags: 'Tags interactions',
    interactions: 'Interactions',
    interaction: 'Intercation',
    userSettings: 'Paramètres utilisateur',
    navigation: 'Paramètre de navigation',
    logistic: 'Logistique',
    production: 'Production',
    scalesCars: 'Poids véhicules',
    usersSettings: 'Paramètres utilisateur',
    formBuilder: 'Générateur de formulaires',
    forms: 'Affichages',
    deliveryOrders: 'Commandes',
    dispositions: 'Dispositions',
    deliveryNotes: "Listes d'envoi",
    userRoles: "Rôles d'utilisateur",
    userRole: "Rôle d'utilisateur",
    dispositionStatuses: 'Statuts de disposition',
    dispositionStatuse: 'Statut de disposition',
    contactPersons: 'Personnes de contact',
    contactPerson: 'Personne de contact',
    assortments: 'Assortiments',
    assortment: 'Assortiment',
    controlCompanies: 'Sociétés de contrôle',
    controlCompany: 'Société de contrôle',
    mines: 'Mines',
    mine: 'Mine',
    schemesOfCargo: 'Relations de transbordement',
    schemeOfCargo: 'Relation de transbordement',
    ship: 'Navire',
    vehicles: 'Moyens de transport',
    vehicle: 'Moyen de transport',
    drivers: 'Chauffeurs',
    driver: 'Chauffeur',
    scale: 'Poids',
    workingPlaces: 'Lieux de travail',
    scaleProtocols: 'Protocoles de pesée',
    scaleProtocol: 'Protocole de pesé',
    positions: 'Positions',
    position: 'Position',
    projects: 'Projets',
    project: 'Projet',
    orderSubjects: 'Produits commandé',
    orderSubject: 'Article commandé',
    warehouse: 'Entrepôt',
    warehouses: 'Entrepôts',
    unitsOfMeasure: 'Unitées de mesure',
    unitOfMeasure: 'Unité de mesure',
    weighingStations: 'Stations de pesage',
    weighingStation: 'Station de pesage',
    appModules: 'Modules système',
    appModule: 'Module système',
    objectVersioningSettings: "Paramètres de version d'objets",
    objectVersioningSetting: "Paramètres de version d'objet",
    reclamationClientDemands: 'Les attentes du client',
    reclamationClientDemand: 'Attente du client',
    reclamationDecisions: 'Décisions',
    reclamationDecision: 'Décision',
    reclamationExecutionTypes: "Types d'exécution de commandes",
    reclamationExecutionType: "Type d'exécution de commande",
    reclamationPerpetrators: 'Responsables des réclamations',
    reclamationPerpetrator: 'Responsable des réclamations',
    reclamationReasons: 'Motifs de la réclamations',
    reclamationReason: 'Motif de la réclamation',
    reclamationSubjects: 'Objets de commandes',
    reclamationSubject: 'Objet de commande',
    reclamationTypes: 'Types de commandes',
    reclamationType: 'Type de commande',
    reclamationStatuses: 'Statuts de commandes',
    reclamationStatus: 'Statut de commande',
    reclamationStatusGroups: 'Groupes de statuts',
    reclamationStatusGroup: 'Groupe de statut',
    paymentTypes: 'Modes de paiement',
    paymentType: 'Modes de paiement',
    cashFlowItems: 'Flux de trésorerie',
    cashFlowItem: 'Elément de flux de trésorerie',
    bankReceipt: 'Virement entrant',
    bankExpense: 'Virement sortant',
    cashReceipt: "Reçu de l'argent",
    cashExpense: 'Argent sortant',
    emailLabels: 'Étiquettes e-mail',
    emailLabel: 'Étiquette e-mail',
    departments: 'Services',
    department: 'Service',
    accessRestrictions: "Restrictions d'accès",
    accessRestriction: "Restriction d'accès",
    eventTypes: "Types d'événements",
    eventType: "Type d'événement",
    standings: "Fonctions de l'employées",
    standing: "Fonctions de l'employé",
    eventStatuses: 'Statuts des événements',
    eventStatus: "Statut d'événements",
    userSettingItems: "Définition des paramètres de l'utilisateurs",
    userSettingItem: "Définition des paramètres de l'utilisateur",
    userSettingGroup: 'Groupe de paramètres utilisateur',
    appSettingGroup: 'Groupe de paramètres système',
    appSettingItem: 'Définition des paramètres système',
    appSettingItems: 'Définition des paramètre système',
    appSettings: 'Paramètres système',
    appSetting: 'Paramètre système',
    documentNumerators: 'Numéroteurs de documents',
    viewSettings: "Paramètres d'affichage",
    viewSetting: "Paramètre de l'affichage",
    appObjects: 'Objets du système',
    appObject: 'Objet du système',
    boxes: 'Cellules de stockage',
    box: 'Cellule de stockage',
    inventoryDocuments: 'Inventaire',
    inventoryStatuses: "Statuettes d'inventaire",
    inventoryCategories: "Catégories d'inventaire",
    vatRates: 'Taux de TVA',
    financialPeriods: 'Conditions financières',
    shipment: 'Envoie',
    settlements: 'Les implantations',
    shippingOfComplaintItems: 'Expédition des articles de réclamation',
    customerRequestStatuses: "Statuts de la demande d'offre",
    customerRequestAnalytics: 'Analyse des offres',
    customerRequestTags: "Tag demande d'offre",
    bpExemplars: "Processus d'affaires",
    salesOrderStatus: 'Statut de la commande client',
    action: 'Action',
    emailTemplateEdition: "Modification d'un modèle d'email",
    availableNavigation: 'Navigation disponible (Menu)',
    remainingViews: 'Autres vues',
    exchangeObjectEdition: "Editer l'objet d'échange",
    addUpdateProductionOrders: 'Ajout/Modification des ordres de fabrication',
    addEditHistory: "Ajout/Modification de l'historique",
    additionalParameters: 'Paramètres supplémentaires',
    productVariantDefinitions: 'Définitions des variantes de produit',
    productVariants: 'Variantes de produit',
    productVariant: 'Variante de produit',
    deliveryRoutes: "Listes d'envoie",
    deliveryRoute: "Liste d'envoie",
    deliveryScheduleStatuses: "Statuts des plans d'expédition",
    deliveryScheduleStatus: "État des plans d'expédition",
    localities: 'Villes',
    locality: 'Ville',
    deliverySchedules: "Plans d'expédition",
    deliverySchedule: "Plan de l'expédition",
    punishmentsTypes: 'Types de frais de stationnement',
    punishmentType: 'Type de frais de stationnement',
    punishments: 'Frais de stationnement',
    punishment: 'Frais de stationnement',
    reportSettings: 'Paramètres des rapports',
    reportSetting: 'Paramètres du rapport',
    roleGroups: "Groupes d'autorisations",
    accessLogs: "Journaux d'enregistrement",
    deliveryPayments: "Paiements pour l'utilisation du stationnement",
    deliveryPayment: "Paiement de l'utilisation du stationnement",
    orderTypes: 'Types de commande',
    orderType: 'Type de commande',
    outgoingSms: 'SMS sortants',
    counterpartyAddresses: 'Adresses des prestataires',
    counterpartyAddress: 'Adresse du prestataire',
    userNavigation: 'Paramétrage de la navigation utilisateur',
    ordersForTransports: 'Ordres de transport',
    orderForTransport: 'Ordre de transport',
  },
  navbar: {
    logOut: 'Déconnexion',
    clearAll: 'Tout supprimer',
    profile: 'Mon profil',
    newNotes: 'Nouvelles notifications',
    checkAll: 'Vérifiez-les tous',
    hello: 'Bonjour!',
    notifications: 'Notifications',
  },
  valueTypes: {
    number: 'Nombre',
    date: 'Date',
    string: 'Texte',
    boolean: 'Logique',
    ref: 'Référence',
  },
  login: {
    description: "Saisissez votre identifiant et votre mot de passe pour accéder au panneau d'administration",
    username: 'Utilisateur',
    usernamePh: 'Saisissez votre identifiant',
    invalidLogin: 'E-mail ou mot de passe utilisateur incorrecte',
    unexpectedError: "Une erreur inattendue s'est produite, veuillez réessayer",
    password: 'Mot de passe',
    passwordPh: 'Entrez votre mot de passe',
    forgotPassword: 'Mot de passe oublié ?',
    logIn: "S'identifier",
    noHaveAccount: "Vous n'avez pas de compte ?",
    register: 'Inscrivez-vous',
    rememberMe: 'Se souvenir de moi',
    securityCheck: 'Vérification de sécurité',
    sendNewCode: 'Envoyer un nouveau code',
    authCode: 'Le code de vérification',
    authCodePh: 'Entrez le code de vérification',
    invalidAuthCode: 'Code de vérification mal saisi',
    outOfDateCode: 'Le code de vérification a expiré. Veuillez en demander un nouveau.',
  },
  register: {
    title: "Inscription de l'utilisateur",
    titleDescription: "Vous n'avez pas de compte ? Inscrivez-vous, cela vous prendra moins d'une minute",
    firstLastName: 'Nom et prénom',
    enterDetailsPh: 'Entrez vos coordonnées',
    email: 'E-mail',
    emailPh: 'Entrez votre e-mail',
    login: "S'identifier",
    loginPh: 'Saisissez votre identifiant',
    terms: "Conditions d'utilisation",
    register: 'Inscrivez-vous',
    haveAccount: 'Vous avez déjà un compte?',
    confirm: 'Votre compte a été enregistré avec succès',
    notFilledAllFilds: "Tous les champs n'ont pas été remplis",
    sendTo: 'Un courriel a été envoyé à',
    emailCheck: 'Vérifiez votre courriel et cliquez sur le lien ci-joint pour réinitialiser votre mot de passe',
    backHome: "Retournez à la page d'accueil",
    emailConfirmed: "L'adresse mail a été confirmée",
    emailNotConfirmed: "Échec de la confirmation de l'adresse mail ",
    resendYourEmail: 'Renvoyer votre email',
    enterValidEmail: "L'adresse email est incorrecte",
    emailSent: 'E-mail envoyé avec succès. Veuillez vérifier votre boîte de réception',
    emailSentError: "Une erreur s'est produite lors de la tentative d'envoi de l'email. Veuillez réessayer plus tard",
    passwordLinkSent: "Le lien vient d'être envoyé pour effectuer un changement de mot de passe",
    enterNewPassword: 'Veuillez saisir le nouveau mot de passe',
    enterPasswordLimit: 'Veuillez entrer au moins 7 caractères',
    reenterPassword: 'Veuillez saisir de nouveau votre mot de passe',
    reenterPasswordError: "Le mot de passe n'est pas le même",
    reenterEmail: 'Veuillez saisir de nouveau votre adresse e-mail afin de recevoir un nouveau lien',
    resendEmail: "Renvoyer l'email",
    canResetPasswordMsg: 'Vous pouvez désormais modifier votre mot de passe',
    resetPasswordLate: "Le temps d'attentes a expiré. Veuillez essayer de renvoyer l'email",
    resetPasswordError: "Une erreur s'est produite lors du traitement de votre demande. Veuillez réessayer à nouveau les informations de réinitialisation",
    name: 'Prénom',
    surename: 'Nom',
  },
  msg: {
    authCodeSended: 'Un nouveau code de vérification a été envoyé',
    restore: "L'élément sera restauré !",
    delete: "L'élément sera marqué pour suppression!",
    deleteError: "Erreur de suppression d'élément",
    catalogWriteError: "Échec de l'enregistrement de l'élément de catalogue",
    catalogWriteSuccess: "L'élément de catalogue a été enregistré",
    filingError: 'Données incorrectes',
    createTrackingSuccess: "L'objet a été ajouté au suivi",
    createTrackingError: "Impossible d'ajouter l'objet au suivi",
    technicalBreak: 'Coupure technique',
    groupHasNestedItems: 'Le groupe a des éléments ',
    noFoundResults: 'No results found for',
    startTypingForSearch: 'Start typing to search.',
    searchResultsFor: 'Rechercher des résultats pour',
    passwordConfirmationError: 'Les mots de passe ne correspondent pas',
    noRelatedViewSettings: "Il n'y a pas de paramètres d'affichage associés",
    setDefaultSettings: 'Paramètres utilisateur supprimés.',
  },
  reclamation: {
    new: 'Nouvelle réclamation',
    add: 'Ajouter une réclamation',
    addUpdatePositions: 'Ajouter / Modifier la commande de réclamation',
    addOrder: 'Ajouter une commande',
    productionJobNumber: 'Numéro de commande production',
    open: 'Ouvert',
    ongoing: 'En cours',
    toReceive: 'À récupérer',
    negative: 'Refusé',
  },
  customerRequest: {
    title: "Demande d'offre",
    new: "Nouvelle demande d'offre",
    newVersion: 'Nouvelle version',
    edit: "Modification de la demande d'offre numéro",
    presentation: "Demande d'offre n°{0} de {1}",
    createNew: 'Créer une demande',
    editStatus: 'Changement de statut',
    addComment: 'Nouveau commentaire',
    editResult: 'Modification du résultat',
    msg: {
      selectItemFirst: "Choisissez d'abord l'offre !",
      return: "L'offre sera restaurée !",
      delete: "L'offre sera marquée pour suppression!",
      orderAvailable: 'Une offre avec cette référence existe déjà: {0}',
      emptyCustomer: 'Client non rempli !',
      emptyReference: 'Référence non remplie !',
      noWrite: "L'offre doit être enregistrée!",
    },
    editDecision: 'Changement de décision',
    newStatus: 'Sélectionnez un nouveau statut',
    selectDecision: 'Choisissez une décision',
    newDecision: 'Nouvelle décision',
    goToAnalytics: 'Aller aux analyses',
    startBp: 'Début des processus commerciaux',
  },
  interaction: {
    new: 'Nouvelle interaction',
    newVersion: "Nouvelle version de l'interaction",
    edit: "Modification de l'interaction nr",
    presentation: "Numéro d'interaction {0} de {1}",
    addComment: 'Nouveau commentaire',
    addEvent: 'Nouvel évènement',
    selectFiles: 'Sélectionnez les fichiers!',
    msg: {
      selectItemFirst: "Choisissez d'abord l'interaction !",
      return: "L'interaction sera restaurée !",
      delete: "L'interaction sera marquée pour suppression!",
      orderAvailable: 'Une interaction avec cette référence existe déjà: {0}',
      emptyCustomer: 'Client non rempli !',
      noWrite: "L'interaction doit être enregistrée!",
    },
    addNewVersion: 'Ajouter une nouvelle version',
    numberEdition: "Modification du numéro d'interaction",
    number: "Numéro d'interaction",
    actualStatus: 'Statut actuel',
    versionsHistory: 'Historique de la version',
    commentsAndLinkedEmails: 'Commentaires / Emails associés',
    tasksAndSalesOrders: 'Tâches client / Commandes',
    attachments: "Pièces jointes de l'interaction",
  },
  order: {
    title: 'Commandes clients',
    objectTitle: 'Commandes',
    new: 'Nouvelles commandes',
    presentation: 'N° de commande {0} de {1}',
    createTask: 'Créer une tâche',
    msg: {
      selectOrderFirst: "Veuillez d'abord sélectionner une commande!",
      return: 'La commande sera rétablie !',
      delete: 'La commande sera marquée pour suppression!',
      orderAvailable: 'Une commande avec cette référence existe déjà: {0}',
      confirmRemoveProduct: 'Voulez-vous vraiment supprimer ce produit?',
      emptyCustomer: 'Client non rempli !',
      emptyStatus: 'Statut non rempli!',
      emptyCurrency: 'Devise non remplie!',
      emptyReference: 'Référence non remplie !',
      emptyDeliveryMethod: 'Mode de livraison non rempli!',
      emptyFields: 'Tous les champs obligatoires non remplis!',
      writeOrder: 'La commande doit être enregistrée !',
      writed: 'La commande a été enregistrée',
      confirmDouble: 'Une commande avec cette référence existe déjà. Confirmez-vous que vous souhaitez passer à la commande?',
      emptyBlock: 'Champ non rempli!',
    },
    status: {
      OFERTA: 'Offre de prix',
      NEW: 'Nouvelles commandes',
      CONFIRMED: 'Confirmé',
      READY_FOR_PRODUCTION: 'Prêt pour la fabrication',
      CANCELED: 'Annulé',
      CLOSED: 'Fermé',
    },
    deliveryMethods: {
      STANDARD: 'Standard',
      UNTIL_CERTAIN_DATE: "Jusqu'à une certaine date",
      ON_CERTAIN_DATE: 'Pour une date précise',
      VIP: 'VIP',
    },
    packageMaterial: {
      Karton: 'Carton',
      Folia: 'Papier bulle',
    },
    enterReference: 'Entrez une référence',
    enterProject: 'Entrez un nom pour le projet',
    enterDeliveryMethod: 'Sélectionnez le type de livraison',
    enterMaterial: 'Choix du matériel',
    shipmentDays: 'Jours de départ',
    roadDays: 'Jours en route',
    deliveryDays: 'Jours de livraison',
    byOrderPeriod: "Suivant l'étendue de commande",
    priceDetails: 'Détails du prix',
    orderName: 'Commande',
  },
  paymentOperation: {
    title: 'Opérations de paiement',
    objectTitle: 'Paiement',
    new: 'Nouveau',
    presentation: 'Opération de paiement n° {0} du {1}',
    createTask: 'Créer une tâche',
    msg: {
      selectOrderFirst: "Veuillez d'abord sélectionner une opération !",
      return: "L'opération de paiement sera rétablie !",
      delete: "L'opération de paiement a été marquée pour suppression!",
      orderAvailable: "L'opération de paiement avec cette référence existe déjà: {0}",
      emptyCustomer: 'Client non rempli !',
      emptyCurrency: 'Devise non remplie!',
      emptyReference: 'Référence non remplie !',
      emptyFields: 'Tous les champs obligatoires non remplis!',
      writeOrder: "L'opération de paiement doit être sauvegardée !",
      writed: "L'opération de paiement a été enregistrée",
      confirmDouble: "Une opération de paiement avec cette référence existe déjà. Confirmez-vous que vous souhaitez passer à l'offre?",
    },
    enterReference: 'Saisissez une référence',
    enterProject: 'Saisissez un nom de projet',
  },
  enums: {
    OBJECT_TYPES: {
      catalog: 'Catalogue',
      document: 'Document',
      register: 'Registre',
      report: 'Rapport',
    },
    VIEW_TYPES: {
      list: 'Liste',
      detail: 'Détail',
      select: 'Choix',
      other: 'Autre',
    },
    IMPORTANCES: {
      NOT_SET: 'Aucun paramètre',
      HIGH: 'Haute',
      NORMAL: 'Normale',
      LOW: 'Faible',
    },
    VERSIONING_METHODS: {
      create: 'A la création',
      update: 'Lors de la mise à jour',
      confirm: 'Sur approbation',
      all: 'Toutes les opérations',
    },
    changeTypes: {
      create: 'Ajouter',
      update: 'Changer',
    },
    changedTypes: {
      create: 'Ajouter',
      update: 'Changer',
    },
    navigationPlacings: {
      general: 'Important',
      usual: 'En général',
      other: 'Autres',
    },
    contactTypes: {
      messenger: 'Messenger',
      phone: 'Téléphone',
      email: 'E-mail',
      skype: 'Skype',
      social: 'Réseaux sociaux',
    },
    typesOfPayment: {
      Cash: 'Espèces',
      Bank: 'Banque',
    },
    statusList: {
      Finished: 'Fini',
      Ongoing: 'En cours',
    },
    typesOfMovement: {
      Receipt: 'Revenu',
      Expense: 'Dépense',
    },
    transportTypes: {
      Car: 'Voiture',
      Trailer: 'Semi-remorque / Remorque',
    },
    projectStatuses: {
      Finished: 'Fini',
      Ongoing: 'En cours',
    },
    PAYMENT_TYPE_STATUSES: {
      Finished: 'Fini',
      Ongoing: 'En cours',
    },
    TEXT_POSITION: {
      begin: 'Au début',
      part: 'Au milieu',
      end: 'À la fin',
    },
    TYPE_OF_OPERATION: {
      Shipment: 'Sortant',
      Receipt: 'Rentrant',
      Reweighing: 'Principalement',
      DirectVariantShipment: 'Direcetment sortant',
      DirectVariantReceipt: 'Direcetement entrant',
      Resigning: 'Réformation',
      Relocation: 'Rélocalisations',
    },
    TYPE_OF_DOCUMENT: {
      Automobile: 'Automobile',
      Railway: 'Ferroviaire',
      Water: 'Nautique',
      Internal: 'Interne',
    },
    INVENTORY_CATEGORY_TYPE: {
      income: 'Revenu',
      shipment: 'Envoi',
      transfer: 'Déplacer',
    },
    DATA_TYPES: {
      number: 'Nombre',
      date: 'Date',
      text: 'Texte',
      string: 'Ligne',
      boolean: 'Logique',
      object: 'Object',
      enum: 'Liste',
    },
    TEXT_ALIGNS: {
      left: 'À gauche',
      right: 'À droite',
      center: 'Milieu',
    },
    ACTIVE_STATUSES: {
      Active: 'Actif',
      Blocked: 'Inactif',
    },
    PACKAGE_MATERIALS: {
      Folia: 'Papier bulle',
      Karton: 'Carton',
    },
    REPORT_PERIOD_TYPES: {
      date: 'Par date',
      period: 'Par période',
      without: 'Without period',
    },
    TRANSPORT_TYPES: {
      Car: 'Voiture',
      Trailer: 'Semi-remorque / Remorque',
      Carriage: 'Wagon',
    },
    TYPE_OF_DISPOSITION: {
      Loading: 'Chargement en cours',
      Unloading: 'Déchargement en cours',
    },
    PROJECT_STATUSES: {
      Finished: 'Fini',
      Ongoing: 'En cours',
    },
    TYPE_OF_PAYMENT: {
      Cash: 'Espèces',
      Bank: 'Banque',
    },
    TYPE_OF_MOVEMENT: {
      Receipt: 'Revenu',
      Expense: 'Dépense',
    },
    PROTOCOL_SCALE: {
      Mechanical: 'Mécanique',
      IoT: 'IoT',
      'TCP/IP': 'TCP/IP',
      Modbus: 'Modbus',
      COM: 'COM',
      S7: 'S7',
    },
    PROTOCOL_SCALE_PARSE: {
      ToledoSimple: 'ToledoSimple',
      ToledoSimpleTwice: 'ToledoSimpleTwice',
      ToledoModbus: 'ToledoModbus',
      IT4000E: 'IT4000E',
    },
    CAR_QUEUE_STATUS: {
      OnTheWay: 'En route',
      EntryReservedWithBuffer: 'Réservation de tampon',
      Accepted: "Fil d'attente",
      EntryReserved: 'Réservation sans tampon',
      EntryAllowed: 'Départ autorisé',
      ParkingExit: 'Il a quitté le parking',
      TerminalExit: 'A quitté le terminal',
      Deleted: 'Annulé',
      EntryAddParking: 'Sur parking de contrôle',
      ExitAddParking: 'A quitté le parking de contrôle',
      Paused: 'Pose',
    },
    RESEARCH_RESULT: {
      Positive: 'Test positif',
      Negative: 'Test négatif',
    },
    VEHICLE_TYPE: {
      truck: 'Camions',
      car: "Jusqu'à 3,5 t",
    },
    TRANSPORT_WAYS: {
      shippingCompany: 'Transporteur extérieur',
      ourTransport: 'Notre transports',
    },
    BOM_STATUSES: {
      active: 'actif',
      draft: 'brouillon',
      canceled: 'annulé',
    },
  },
  pricelistKinds: {
    main: 'De base',
    surcharge: 'Supplément',
  },
  pricingMethods: {
    formula: 'Formule',
    fixed: 'Prix fixe',
    table: 'Tableau de prix',
    percent: 'Pourcentage',
    scale: 'Échelle de prix',
  },
  discountTypes: {
    price: 'Prix',
    percent: 'Pourcentage',
    formula: 'Formule',
  },
  discountBelongs: {
    main: 'Tarifs de base',
    surcharge: 'Suppléments',
    main_and_surcharge: 'Tarifs et suppléments',
    surcharge_and_price: 'Suppléments et articles supplémentaires',
    all: 'Tout',
  },
  task: {
    executionReceive: "Accepter l'exécution",
    showExecuted: "Afficher l'exécution terminé",
    showMyne: 'Afficher le mien',
    description: 'Description',
    executionResult: "Résultat de l'exécution de la tâche",
    newTask: 'Nouvelle tâche',
    forExecutorRole: "Rôle de l'exécutant",
    forExecutor: "Pour l'exécutant",
    redirection: 'Redirection de la tâche',
    showTasks: 'Afficher les tâches',
  },
  commands: {
    accept: 'Accepter',
    search: 'Recherche',
    add: 'Ajouter',
    addNew: 'Ajouter un nouveau',
    delete: 'Supprimer',
    clear: 'Effacer',
    cancel: 'Annuler',
    ok: 'OK',
    writeAndClose: 'Enregistrer et fermer',
    write: 'Enregistrer',
    close: 'Fermer',
    autofill: 'Remplissage automatique',
    addProduct: 'Ajouter un produit',
    addExtraProduct: 'Ajouter des éléments en plus',
    addFile: 'Ajouter un fichier',
    addToCalendar: 'Ajouter dans le calendrier',
    eventsList: "Liste d'événements",
    addGroup: 'Ajouter un groupe',
    addSetting: 'Ajouter un paramètre',
    select: 'Selectionner',
    configProduct: 'Configurer le produit',
    confirm: 'Confirmer',
    confirmSelected: 'Confirmer le choix',
    confirmPrices: 'Confirmer le prix',
    calculate: 'Calcule',
    execute: 'Exécuter',
    redirectTaskExecution: "Exécuter et rediriger vers l'étape suivante",
    createOrder: 'Ouvrir la commande',
    runAndClose: 'Exécuter et fermer',
    update: 'Rafraîchir',
    download: 'Télécharger',
    edit: 'Modifier',
    addComment: 'Ajouter un commentaire',
    writeEmail: 'Écrire un e-mail',
    addEvent: 'Ajouter un événement',
    showCanceled: 'Afficher inactif',
    send: 'Envoyer',
    sendResult: 'Envoyez le résultat',
    refresh: 'Rafraîchir',
    resend: "L'envoyer à nouveau",
    resetPassword: 'Réinitialiser le mot de passe',
    redirect: 'Rediriger',
    createFrom: 'Créer à partir de',
    print: 'Imprimer',
    more: 'En savoir plus',
    setStatus: 'Définir le statut',
    sendListToAuthor: "Envoyer la liste à l'auteur",
    generate: 'Générer',
    change: 'Modifier',
    getComputerID: "Obtenir l'identifiant du PC",
    getInfoEquipment: "Télécharger les informations sur l'appareil",
    attachTo: 'Joindre à',
    sendNow: 'Envoyer maintenant',
    selectAll: 'Sélectionner tout',
    copy: 'Copie',
    deleteRestore: 'Supprimer / Restaurer',
    showHistory: "Afficher l'historique des modifications",
    open: 'Ouvrir',
    compare: 'Comparer',
    advancedSearch: 'Recherche Avancée',
    addViewSetting: "Ajouter un paramètre d'affichage",
    moveUp: 'Relever',
    moveDown: 'Descendre',
    changeView: 'Changer de vue',
    forward: 'Rediriger',
    setDefault: 'Définir par défaut',
    insertNumber: 'Entrez le numéro',
    runTimeTracking: "Suivi de l'heure de démarrage",
    markAll: 'Tout sélectionner',
    unmarkAll: 'Décocher tout',
    showDetail: 'Montre-moi les détails',
    enterDescription: 'Entrez une description',
    activate: 'Activer',
    allowManual: 'Modifier',
    enterNumber: 'Entrez le numéro',
    enterComment: 'Entrez un commentaire',
    enterSth: 'Entrez quelque chose',
    completeAllConsts: 'Compléter tous les coûts',
    addRefreshResult: 'Ajouter/Mettre à jour le résultat',
    goToEditing: "Aller à l'édition",
    goToRegister: "Aller à l'enregistrement",
    goToParentDocument: 'Accéder au document principal',
    addAttachment: 'Ajouter une pièce jointe',
    writeChanges: 'Sauvegarder les modifications',
    addTitle: 'Ajouter un titre',
    addPhase: 'Ajouter une étape',
    selectContactFromList: 'Sélectionnez un contact dans la liste',
    addFromList: 'Ajouter à partir de la liste',
    mannualEntry: 'Saisie manuelle',
    editNumber: 'Modifier le numéro',
    reset: 'Réinitialiser',
    addNotificationContent: 'Ajouter le contenu des notifications',
    enterCode: 'Entrer le code',
    inputTranslations: 'Saisir les traductions',
    changeStatus: 'Modifier le statut',
    changeNumber: 'Modifier le numéro',
    changeDecision: 'Modifier votre décision',
    openReport: 'Ouvrir le rapport',
    salesReport: 'Rapport des ventes',
    addFields: 'Ajouter des champs',
    addField: 'Ajouter un champ',
    addFilters: 'Ajouter des filtres',
    end: 'Terminer',
    setPeriod: 'Définir la période',
    addCustomer: 'Ajouter un client',
    planRoute: 'Planifiez votre itinéraire',
    calculateRoute: "Calculer l'itinéraire",
    findOptimalRoute: "Trouver l'itinéraire optimal",
    copySettingsFromUser: "Copier les paramètres d'un autre utilisateur",
    addAction: "Ajout d'actions",
    editAction: "Édition d'action",
    getFileForTranslation: 'Télécharger le fichier de traduction',
    loadTranslationFromFile: 'Charger la traduction à partir du fichier',
  },
  common: {
    search: 'Recherche...',
    filter: 'Filtre',
    filters: 'Filtres',
    modalTitle: 'Attention !',
    emptyField: '<vide>',
    currency: 'Devise',
    mainData: 'Informations de base',
    products: 'Produits',
    files: 'Fichiers',
    notEmptyField: 'Ce champ ne peut pas être vide',
    selectDate: 'Sélectionnez une date',
    enterComment: 'Saisir des remarques',
    enterQuantity: 'Saisir la quantité',
    total: 'Total',
    selectFileTitle: 'Choisissez un fichier!',
    selectProductTitle: 'Choisissez le produit!',
    noSelectedFiles: 'Aucun fichier sélectionné',
    searchCustomer: 'Recherche client',
    double: 'Doublon',
    incorrectData: 'Données invalides saisies dans le champ!',
    from: 'À partir de',
    to: "Jusqu'à",
    noFound: '<non trouvé>',
    selected: 'Choisi',
    addinDimensions: 'Mesures supplémentaires',
    numberOfPieces: 'Quantité',
    length: 'Longeur',
    width: 'Largeur',
    lengthWidth: 'Longeur/Largeur',
    height: 'Hauteur',
    recalculation: 'Calculation',
    active: 'Active',
    deactive: 'Inactif',
    shipData: 'Donnés du véhicule',
    carrierData: 'Informations sur le transporteur',
    productData: 'Données sur le produit',
    dateAdded: "Date d'ajout",
    picturePath: "Vers l'image",
    enterPictureName: "Nom de l'image",
    describeProduct: 'Décrivez le produit...',
    customerData: 'Données client',
    erp: 'ERP',
    executorData: 'Données du contractant des tâches',
    executorRoleData: "Données sur le rôle de l'entrepreneur",
    emptyExecutorList: 'La liste est vide...',
    emptyUserList: 'La liste des utilisateurs est vide...',
    emptyHistoryList: 'Aucun enregistrement de modification trouvé...',
    days: 'Jours',
    hours: 'Heures',
    minutes: 'Minutes',
    executionToday: "Réalisation aujourd'hui",
    taskTemplatesData: 'Données de modèle de tâches',
    fillByTemplate: '-- Remplir à partir du modèle --',
    enterName: 'Saisissez un nom',
    selectImportance: 'Choisissez une importance',
    selectExecutorRole: 'Choisissez le rôle du sous-traitant',
    selectExecutor: 'Choisissez le sous-traitant',
    history: 'Historique',
    yes: 'Oui',
    no: 'Non',
    selectForm: 'Sélectionnez un document',
    selectPossition: 'Sélectionnez la positions',
    searchItem: 'Recherche',
    enterTextHere: 'Entrez le texte ici...',
    prices: 'Prix',
    perPage: 'Limites',
    msg: 'Notifications',
    containsWords: 'Comprend des mots',
    subsystem: 'Sous Système',
    partition: 'Répartition',
    view: 'Visualiser',
    preview: 'Aperçu',
    orderData: 'Données de commande',
    dispositionData: 'Données de disposition',
    deliveryNoteData: 'Données du bon de livraison',
    vendorsAndCustomers: 'Fournisseurs et clients',
    vendorsAndCustomersData: 'Données clients et fournisseurs',
    notSelected: 'Non séléctionné',
    msges: {
      selectItemFirst: "Veuillez d'abord sélectionner une demande d'offre!",
    },
    settings: 'Paramètres',
    general: 'Général',
    externalCodes: 'Codes externes',
    forwarders: 'Transporteurs',
    vehicle: 'Véhicules',
    valueType: 'Type de valeur',
    register: "S'inscrire",
    actions: 'Actions',
    showMore: 'Afficher plus',
    processingError: "Une erreur s'est produite lors de l'exécution de l'opération",
    selectUser: 'Sélectionnez un utilisateur',
    selectMethod: 'Sélectionner la méthode',
    selectEmployee: "Choix de l'employé",
    signatureSettings: "Paramètres e-mail de l'utilisateur",
    textPosition: 'Emplacement dans le texte',
    views: 'Aperçus',
    navigation: 'Navigation',
    fields: 'Champs',
    constructor: 'Fabricant',
    quickFilter: 'Filtre rapide',
    quickFilters: 'Filtres rapides',
    formatter: 'Mise en page',
    format: 'Format',
    availableFields: 'Champ disponible',
    noFieldsAvailable: 'Aucun champ disponible',
    noFiltersAvailable: 'Aucun filtres disponibles',
    sortable: 'Triables',
    items: 'Articles',
    help: 'Aide',
    wastes: 'Déchets',
    taras: 'Tare',
    reservations: 'Réservations',
    loading: 'Chargement',
    additionalProducts: 'Produits supplémentaires',
    styles: 'Style',
    missingParameter: 'Manque',
    eventType: "Type d'événement",
    eventEnd: "Fin de l'événement",
    eventStart: "Le début de l'événement",
    start: 'Départ',
    setZero: 'Remise à zéro',
    setZeroReceipt: 'Réinitialiser le reçu',
    technicalData: 'Données techniques',
    writeComment: 'Saisissez un commentaire',
    selectLanguage: 'Choisissez la langue',
    financialPeriod: 'Période financière',
    addUpdateCostsOfComplaintOrder: "Ajout / Modification des frais d'une commande de réclamation",
    fileDescription: 'Description du fichier',
    complaintVerificationProtocol: 'Protocole de vérification de réclamation',
    registration: 'DÉCLARATION',
    undefined: 'Indéfini',
    customerFiles: 'Fichiers clients',
    searchAuthor: "Rechercher l'auteur",
    searchAssortment: 'Rechercher un assortiment',
    searchAddress: 'Rechercher une adresse',
    searchDriver: 'Rechercher un chauffeur',
    searchDirector: 'Rechercher un gestionnaire',
    searchDispAuthor: "Rechercher l'auteur de la disposition",
    searchOrderAuthor: "Rechercher l'auteur de la commande",
    searchWeighingStation: 'Rechercher une station de pesée',
    searchVehicle: 'Rechercher le véhicule',
    searchOriginWarehouse: 'Rechercher un lieu de chargement',
    searchWarehouse: 'Rechercher un entrepôt',
    chooseCarrier: 'Sélectionnez un transporteur',
    searchCategory: 'Rechercher une catégorie',
    searchControlCompany: 'Rechercher une entreprise de contrôle',
    searchOpType: "Rechercher le type d'opération",
    searchProducts: 'Rechercher des produits',
    searchProductTo: 'Rechercher un produit à',
    searchProductVariant: 'Rechercher une variante de produit',
    searchWarehouseTo: 'Rechercher un entrepôt à',
    searchWarehouseFrom: 'Rechercher un entrepôt à partir de',
    searchWarehouseSector: "Rechercher un secteur d'entrepôt",
    searchForwarder: 'Rechercher un transporteur',
    searchScale: 'Rechercher un poids',
    searchShip: 'Rechercher un navire',
    searchRelation: 'Rechercher une relation',
    searchStation: 'Rechercher un poste',
    searchProduct: 'Rechercher des marchandises',
    searchManager: 'Rechercher un commercial',
    searchBase: 'Rechercher la base',
    searchDirective: 'Rechercher une commande',
    searchOrder: 'Rechercher une commande',
    searchCustomerOrder: 'Rechercher une commande client',
    searchExecution: "Recherche d'exécution",
    searchExecutor: 'Rechercher un prestataire',
    searchNettoExecutor: 'Recherche entrepreneur net',
    searchTaraExecutor: "Recherche d'un entrepreneur de tare",
    searchGrossExecutor: 'Recherche entrepreneur brut',
    searchConstructor: 'Rechercher un BE',
    searchTaskExecutor: 'Rechercher un sous-traitant',
    searchOwner: 'Rechercher le propriétaire',
    searchRail: 'Rechercher un wagon',
    searchCarrier: 'Rechercher un transporteur',
    searchCurrency: 'Rechercher la devise',
    searchCountry: 'Rechercher un pays',
    searchLocality: 'Rechercher une ville',
    scanCode: 'Scannez le code',
    scanTicket: 'Scannez le billet',
    scanBarCode: 'Scannez le code-barres',
    searchPaymentDueDate: "Rechercher les dates d'échéance",
    customerRequestNumberEdit: 'Modification du numéro de demande',
    customerRequestNumber: 'Numer zapytania ofertowego',
    searchContractor: "Recherche d'entrepreneur",
    searchType: 'Recherche type',
    searchBasicDocument: 'Rechercher le document principal',
    searchProject: 'Rechercher un projet',
    searchRealization: 'Rechercher une réalisation',
    justification: 'Justification',
    searchAgreement: 'Rechercher un contrat',
    rows: 'Lignes',
    searchSubject: 'Rechercher un élément',
    searchDecision: 'Rechercher une décision',
    searchResponsible: 'Recherche un responsable',
    searchDisposition: 'Rechercher une consigne',
    searchReason: 'Rechercher une raison',
    searchTrailer: 'Rechercher une remorque',
    searchExpectation: 'Rechercher une attente',
    searchStatus: 'Rechercher un statuts',
    searchVendor: 'Rechercher un fournisseur',
    searchPaymentType: 'Rechercher un type de paiement',
    searchCashFlowElement: 'Rechercher un élément de flux de trésorerie',
    searchPosition: 'Rechercher une position',
    searchUnitOfMeassure: 'Rechercher une unité',
    searchMine: 'Rechercher une mine',
    searchVendorCustomer: 'Rechercher un destinataire',
    searchCompletion: 'Rechercher une réalisation',
    searchTrain: 'Rechercher un train',
    numbers: 'Numéros',
    sales: 'VENTE',
    orders: 'Commandes',
    regestrationDate: "Date d'enregistrement",
    filePath: "Chemin d'accès au fichier",
    decisionDate: 'Date de décision',
    administration: 'Administration',
    complaintsInfo: 'Données de réclamation',
    allDays: 'Tous les jours',
    recepientsCondition: 'Condition du destinataire',
    executionCondition: "Conditions d'expédition",
    planedCompletitionDate: 'Date prévue de la réalisation',
    defaultPrinterSettings: "Paramètres par défaut de l'imprimante",
    localSystem: 'Installation locale',
    noGroupsAvailable: 'Aucun regroupement disponible',
    noSortingAvailable: 'Aucun tri disponible',
    noFuncsAvailable: 'Aucun calcul disponible',
    noFilters: 'Aucun filtre',
    filterable: 'Filtrable',
    groupable: 'Groupable',
    functional: 'Calculé',
    fillTranslationsFromDictionary: 'Complétez les traductions du dictionnaire',
    all: 'Tous',
    onlyEmpty: 'Seulement vide',
    selectedFields: 'Champs sélectionnés',
    selectedFilters: 'Filtres sélectionnés',
    details: 'Détails',
    statisticByTraders: 'Statistiques par commerciaux',
    analizeByCustomers: 'Analyse par clients',
    myCalendar: 'Mon calendrier',
    addCalendar: 'Ajouter un calendrier',
    addDisposition: 'Ajouter une consigne',
    shared: 'Commun',
    private: 'Privé',
    attendees: 'Membres de la réunion',
    totalProjects: 'Tout projets',
    totalTasks: 'Toutes les tâches',
    members: 'Membres',
    productivity: 'Productivité',
    customerRequestsByCustomer: "Demandes d'offre par clients",
    customerRequestsStatistics: "Statistiques des demandes d'offres",
    customerRequestsValue: "La valeur des demandes d'offres",
    currentWeek: 'Cet semaine',
    previousWeek: 'La semaine dernière',
    weekNumber: 'Numéro de semaine',
    enterWeekNumber: 'Entrez le numéro de la semaine',
    actual: 'Actuel',
    projection: 'Projection',
    notWeighting: 'En attente de pesée',
    beginWeighting: 'Pesée en cours',
    stopWeighting: 'Pesée arrêtée',
    endWeighting: 'Pesée terminée',
    customerQuantity: 'Nombre actuel de clients',
    interactionQuantity: "Le nombre actuel d'interactions",
    customerRequestsQuantity: "Nombre de demandes d'offres",
    customerRequestsValueActual: "Valeur actuelle des demandes d'offres",
    sinceLastMonth: 'Depuis le mois dernier',
    invoiceProFormPl: 'Facture PROFORMA (pl)',
    invoiceProFormEn: 'Facture PROFORMA (en)',
    anyQuestion: 'Avez-vous des questions?',
    dateOfIssue: "Date d'émission",
    seller: 'Vendeur',
    bankAccount: 'Compte bancaire',
    buyer: 'Acheteur',
    productServiceName: 'Nom du produit/service',
    bruttoPrice: 'Prix brut',
    bruttoWorth: 'Valeur brute',
    worth: 'Valeur',
    inWords: 'En toutes lettres',
    documentIssue: 'Document délivré par',
    documentReceived: 'Document reçu',
    unitOfMeasure: 'Unité de mesure',
    bankName: 'Nom de banque',
    fillNew: 'Remplir pour les nouveaux',
    newContact: 'Nouveau contact',
    orderConfirmPrint: 'Confirmation de commande',
    required: 'Obligatoire',
    labelCols: 'Largeur du titre',
    contentCols: 'Largeur du contenu',
    default: 'Basique',
    links: 'Relations',
    selectCustomer: 'Choisissez un client',
    fieldCols: 'Largeur de champ',
    newRow: 'Nouveau reccourcis',
    noData: 'Aucune donnée',
    allFiles: 'Tous les fichiers',
    query: 'Demande',
    newField: 'Nouveau champ',
    sortDisabled: 'Tri désactivé',
    groupDisabled: 'Groupage désactivé',
    filterDisabled: 'Filtrage désactivé',
    functionalDisabled: 'Énumération désactivée',
    textAlign: 'Disposition',
    manualEditionRedirect: 'Modification manuelle de la redirection',
    funcDisabled: 'Énumération désactivée',
    databaseTranslation: "Traduction de l'interface",
    new: 'New',
  },
  weekDays: {
    Mo: 'Lun',
    Tu: 'Mar',
    We: 'Mer',
    Th: 'Jeu',
    Fr: 'Ven',
    Sa: 'Sam',
    Su: 'Dim',
  },
  operators: {
    '=': 'Égal',
    '!=': 'Non égal',
    '>': 'Supérieur',
    '<': 'Inférieur',
    '>=': 'Supérieur ou égal',
    '<=': 'Inférieur ou égal',
    like: 'Contient',
    notLike: 'Ne contient pas',
    inList: 'Sur liste',
    notInList: 'Pas sur la liste',
    eq: 'Égal',
    ne: 'Non égal',
    gt: 'Supérieur',
    gte: 'Supérieur ou égal',
    lt: 'Inférieur',
    lte: 'Inférieur ou égal',
    iLike: 'Contient',
    notILike: 'Ne contient pas',
    filled: 'Rempli',
    notFilled: 'Non-rempli',
  },
  reports: {
    salesOrderRegister: 'Registre des commandes',
    salesAnalize: 'Analyse des ventes',
    interactionAnalize: 'Analyse des interactions',
    interactionTimeAnalize: 'Analyse interactive du temps de travail',
    inquiriesAnalize: "Analyse des demandes d'offre",
    email: 'Email',
  },
  table: {
    id: 'Id',
    uuid: 'UUID',
    numberStr: 'Numéro (Str)',
    prefix: 'Préfixe',
    sort: 'Lp',
    sortNumber: 'N',
    sortNumberFull: 'Numéro suivant',
    tasks: 'Tâches',
    comments: 'Commentaires',
    code: 'Code',
    number: 'Numéro',
    name: 'Nom',
    fullName: 'Nom complet',
    symbol: 'Symbole',
    dateOfBirth: 'Date de naissance',
    sex: 'Sexe',
    article: 'Article',
    type: 'Type',
    markedToDelete: 'Marqué pour suppression',
    delete: 'Suppression',
    discription: 'Description',
    customer: 'Client',
    reference: 'Référence',
    createdAt: 'Créé',
    updatedAt: 'Date de mise à jour',
    deliveryDate: 'Date de livraison',
    shipmentDate: "Date d'envoie",
    status: 'Statut',
    progressValue: 'Progression',
    statusStr: 'Statut (Str)',
    author: 'Auteur',
    price: 'Prix',
    sum: 'Somme',
    sumNetto: 'Montant NET',
    sumWithoutVAT: 'Montant sans TVA',
    sumVAT: 'Le montant de la TVA',
    sumVat: 'Le montant de la TVA',
    sumWithVAT: 'Montant brute',
    sumBrutto: 'Montant brute',
    currency: 'Devise',
    currencyStr: 'Devise (Str)',
    note: 'Note',
    paymentTerms: 'Conditions de paiement',
    project: 'Project',
    deliveryMethod: "Options d'envoie",
    packageMaterial: "Matériau d'emballage",
    comment: 'Remarques',
    product: 'Produits',
    description: 'Description',
    jOM: 'J.M',
    quantity: 'Quantité',
    discountPercent: '% de remises',
    discountSum: 'Montant de la remises',
    discount: 'Remises',
    fileSize: 'Taille, kB',
    responsible: 'Responsable',
    country: 'Pays',
    email: 'E-mail',
    address: 'Adresse',
    phone: 'Téléphone',
    abbreviation: 'Raccourci',
    actions: 'Actions',
    priceCode: 'Code liste de prix',
    contact: 'Contact',
    howToContact: 'Comment nous contacter',
    executor: 'Exécutant',
    subExecutor: 'Sous-traitant',
    executionPeriod: 'Délai de réalisation',
    changeDate: 'Date de modification',
    user: 'Utilisateurs',
    nip: "Numéro d'identification fiscale",
    region: 'Région',
    pricesType: 'Type de prix',
    customersGroup: 'Groupe de clients',
    language: 'Langue',
    executorName: "Nom de l'exécutant",
    importance: 'Priorité',
    executorRole: "Le rôle de l'exécutant",
    contents: 'Contenu',
    template: 'Modèle',
    base: 'Base',
    task: 'Tâche',
    category: 'Catégorie',
    priority: 'Priorité',
    postDate: "Date d'envoie",
    topic: 'Sujet',
    objectType: "Type d'objet",
    objectDescription: "Descriptif de l'objet",
    objectId: "ID d'objet",
    changeType: "Type d'édition",
    sent: 'Envoyé',
    constructor: 'Fabricant',
    constr: 'Fabricant',
    manager: 'Commercial',
    commission: 'Commission, %',
    version: 'Version',
    orderNumber: 'N ° de commande',
    isActive: 'Active',
    isService: 'Service',
    password: 'Mot de passe',
    isGeneral: 'Général',
    isClosed: 'Fermé',
    baseDocument: 'Document de base',
    emailAccount: 'Compte e-mail',
    sendingDate: "Date d'envoie",
    tags: 'Mots clés',
    proforma: 'PRO-FORMA',
    versionUuid: 'UUID de la version',
    state: 'État',
    date: 'Date',
    ordered: 'Commandé',
    termsOfPayment: 'Date de règlement',
    executionTerm: "Délai d'exécution",
    order: 'Commande',
    resultEmail: 'E-mail',
    days: 'Jours',
    alphaCode2: 'Code Alfa-2',
    alphaCode3: 'Code Alfa-3',
    pricelistKind: 'Type de tarif',
    pricingMethod: "Méthode d'offre",
    from: 'À partir de',
    to: "Jusqu'à",
    confirmed: 'Vérifiée',
    includedInMain: 'Inclus dans la base',
    notUseDiscount: 'Sans remise',
    maxDiscount: 'Remise max',
    priceType: 'Type de prix',
    beginDate: 'Date de début',
    startDate: 'Date de début',
    endDate: 'Date de fin',
    formula: 'Formule',
    belongs: 'Fait référence à',
    discountType: 'Type de remise',
    default: 'Par défaut',
    customerAccess: 'Disponible pour que les clients choisissent',
    documentType: 'Types du documents',
    storeFilesToHardDrive: 'Enregistrer les fichiers sur votre disque dur',
    filters: 'Filtres',
    dimensionX: 'Axe X',
    dimensionY: 'Axe Y',
    max: 'Max',
    min: 'Min',
    step: 'Étape',
    customerLockOrder: 'Bloquer la commande client',
    agreement: 'Contrat',
    offer: 'Offre',
    period: 'Période',
    checkExecution: 'Vérifier la réalisation',
    checker: 'Vérificateur',
    hasLinks: 'Il y a des liens',
    links: 'Lien',
    processed: 'Élaboré',
    icon: 'Icône',
    title: 'Titre',
    parent: 'Appartient à',
    viewType: "Type d'affichage",
    readOnly: 'Lecture seule',
    component: 'Composant',
    store: 'Dépôt',
    model: 'Modèle',
    path: 'Chemin',
    detailPath: "Chemin d'accès à l'élément de modification",
    view: 'Aperçu',
    schemeOfCargo: 'Relation',
    counterparty: 'Propriétaire',
    typeOfOperation: "Type d'opération",
    warehouse: 'Lieu',
    ship: 'Navire',
    scale: 'Poids',
    scale2: 'Poids 2',
    scaleTwo: 'Poids 2',
    weighingStation: 'Station de pesage',
    brutto: 'Brut',
    tare: 'Tare',
    netto: 'Net',
    wagon: 'Wagon',
    loadCapacity: 'Capacité de chargement',
    deliveryOrder: 'Commande',
    forwarder: 'Transitaire',
    doNotLoadToOracle: 'Ne chargez pas dans Oracle',
    expireDate: "Date d'expiration",
    closeDate: 'Date de clôture',
    docType: 'Type',
    warehouseSection: "Secteur de l'entrepôt",
    item: 'Articles',
    declaredTonnage: 'Tonnage déclaré',
    maxLoss: 'Perte maximale',
    numberAndDateSdk: 'Numéro, date SDK',
    numberAndDateDSKAndMRN: 'Numéro, date DSK/MRN',
    customGoodsStatus: 'Le statut douanier de la marchandise',
    carServiceTime: 'Temps de service de voiture',
    controlCompany: 'Société de contrôle',
    correspondence: 'Correspondance',
    descComment: 'Commentaire',
    disposition: 'Disposition',
    deliveryNote: 'Bordereau de livraison',
    trainNumber: 'Numéro de train',
    containerNumber: 'Numéro de conteneur',
    recipient: 'Destinataire',
    operationType: "Type d'opération",
    vendor: 'Fournisseur',
    contractor: 'Prestataire',
    btsStatement: 'Déclaration BTS',
    station: 'Poste',
    warehouseSquare: 'Entrepôt/Place',
    assortmentGmpPLus: 'Assortiment GMP+',
    closed: 'Fermé',
    startOfWeighting: 'Début de la pesée',
    endOfWeighting: 'Fin de la pesée',
    driver: 'Chauffeur',
    carQueueStatus: "État de la file d'attente de la voiture",
    carsQueueStatus: "État de la file d'attente des voitures",
    driverPhoneNumber: 'Numéro de téléphone du chauffeur',
    carNumber: "Numéro d'immatriculation",
    trailer: 'Semi-remorque',
    idNumber: 'Numéro de carte grise',
    carrier: 'Transporteur',
    parkingAriveDateAndTime: "Date et heure d'arrivée au parking",
    retaring: 'Retarer',
    done: 'Terminé',
    multiple: 'Multiple',
    allowRetaring: 'Autoriser le retarage',
    allowSetZero: 'Autoriser la réinitialisation du ticket',
    assortment: 'Assortiment',
    tests: 'Essais',
    dateOfTest: "Date d'essais",
    arriveFrom: 'Arrivée à partir de',
    lockArrivesFrom: "Blocage de l'arrivée à partir de",
    cancelWeighting: 'Annuler la pesée',
    position: 'Position',
    scaleBrutto: 'Poids brut',
    scaleTare: 'Poids de la tare',
    scaleNetto: 'Poids net',
    bruttoTime: 'Date du poids brut',
    tareTime: 'Date du poids de la tare',
    nettoTime: 'Date du pods net',
    bruttoAuthor: 'Auteur brut',
    tareAuthor: 'Auteur de la tare',
    nettoAuthor: 'Auteur net',
    approved: 'Reçu',
    approvedDate: 'Date du reçu',
    approvedAuthor: 'Auteur du reçu',
    carriageNumber: 'Numéro de wagon',
    notUse: 'Ne pas utiliser',
    useActualWarehouse: 'Utiliser le stockage réel',
    sendDriversSms: 'Envoyer des sms aux chauffeurs',
    useProductCondition: "Utiliser l'état du produit",
    numberOfCopies: 'Nombre de copies',
    returnToWarehouse: "Retour à l'entrepôt",
    disableControlOnScales: 'Désactiver les vérifications sur les balances',
    reverseOperation: 'Opération inverse',
    disableControlInDispositions: 'Désactiver les contrôles sur les dispositions',
    actualWarehouse: "Lieu d'établissement",
    deliveryNoteNumber: 'Numéro du bordereau de livraison',
    mine: 'Mine',
    advicesNumber: "Numéro d'avis / entrepôt",
    dispositionAuthor: 'Dispositions emise par',
    orderAuthor: 'La commande est émise par',
    paramValues: 'Signification des paramètres',
    queryParam: 'Paramètre Query (?)',
    hashParam: 'Paramètre Hash (#)',
    typeOfDocument: 'Type de document',
    arrivalDateDriver: "Date et heure d'arrivée au parking",
    vehicle: "Numéro d'immatriculation",
    vehicleWagon: 'Numéro de wagon',
    key: 'Clé',
    read: 'Aperçu',
    modify: 'Édition',
    added: 'Ci-joint',
    color: 'Couleur',
    accessRole: "Le rôle de l'accès",
    weightingType: 'Type de pesée',
    firstWeighting: 'Première pesée',
    secondWeighting: 'Deuxième pesée',
    barCode: 'Code barres',
    driverTicket: 'Billet chauffeur',
    entryTicket: "Ticket d'entrée",
    defaultWarehouse: 'Entrepôt principal',
    externalCode: 'Code externe',
    carBrand: 'Marque de voiture',
    declaredTare: 'Tare déclarée',
    idCard: "Carte d'identité",
    attentionCount: 'Nombre de commentaires',
    isCardNumber: "Numéro de carte d'identité",
    isCardSerial: "Numéro de série carte d'identité",
    termsOfUse: "Accepter les conditions d'utilisation",
    desktopMode: 'Mode de bureau (AMP)',
    desktopName: 'Nom du poste de travail (AMP)',
    printerName: "Nom de l'imprimante",
    printerList: 'Liste des imprimantes',
    selectProtocol: 'Choix du protocole',
    server: 'Serveur',
    port: 'Port',
    api: 'Api',
    sendCommand: 'Envoyez la commande',
    typeParse: "Type d'analyse",
    registry: 'Registre',
    comPort: 'Port COM',
    guarantee: 'Garantie',
    initDate: 'Date de début',
    capasity: 'Capacité',
    carsBuffer: 'Tampon de voiture',
    externalId: 'Identifiant externe',
    queueOn: "Fil d'attente",
    reflect: 'Refléter',
    notes: 'Note',
    scalesType: 'Type de poids',
    automaticWeightingEnd: 'Fin de pesée automatique',
    barCodePrefix: 'Préfixe du code barres',
    handlers: 'Gestionnaires',
    scaleProtocol: 'Protocole de la pesée',
    videoRecorder: 'Enregistreur vidéo',
    usingVideoRecorder: 'Utiliser un enregistreur vidéo',
    inquiry: 'Demande de renseignements',
    ptotocols: 'Protocole',
    numberCopies: 'Nombre de copies',
    orientation: 'Orientation',
    size: 'Dimension',
    rollPrinter: 'Imprimante à rouleaux',
    clientId: 'Identité du client',
    computerName: "Nom de l'ordinateur",
    networkPort: 'Port réseau',
    prohibitionWorkSeveralSessions: 'Interdiction de travailler en plusieurs sessions',
    issuanceRailwayInstructions: "Émission d'instructions ferroviaires",
    predefinedName: 'Nom défini',
    supplier: 'Fournisseur',
    versioningMethod: 'Méthode de gestion des versions',
    main: 'Principal',
    postCode: 'Code postale',
    city: 'Ville',
    adminAccess: 'Accès administrateur',
    userAccess: 'Accès utilisateur',
    statusgroup: 'Groupe de statut',
    code1C: 'Code 1C',
    sortId: 'Ordre',
    placing: 'Emplacement',
    paymentType: 'Mode de paiement',
    cashFlowItem: 'Élément de flux de trésorerie',
    sumPayment: 'Montant du paiement',
    typeOfMovement: 'Rodzaj ruchu',
    typeOfPayment: 'Typ płatności',
    startTime: 'Heure de départ',
    endTime: 'Fin du temps',
    leader: 'Directeur',
    deputy: 'Remplaçant',
    employee: 'Employé',
    useRestriction: 'Restrictions générales',
    useDepartmentRestriction: 'Utiliser la restriction du service',
    calendarEvent: 'Calendrier des événements',
    valueType: 'Type de valeur',
    field: 'Champ',
    searchField: 'Champ pour rechercher',
    operator: 'Condition',
    value: 'Signification',
    moving: 'Déplacement',
    yearNumber: 'Année',
    lastNumber: 'Dernier chiffre',
    singularName: 'Nom unique',
    singularTitle: 'Titre unique',
    lang: 'Traductions',
    beginWeighting: 'Début de la pesée',
    endWeighting: 'Fin de pesée',
    nettoOverAll: 'Net général T',
    nettoRegistered: 'Net enregistré T',
    enumerator: 'Compteur',
    appObject: 'Objet du système',
    object: 'Objet',
    incomingDate: 'Date du document',
    incomingNumber: 'Numéro de document externe',
    client: 'Clients',
    contactPerson: 'Personne à contacter',
    basicDocument: 'Document de base',
    salesOrder: 'Commande client',
    exchangeRate: 'Taux de change',
    exchangeRateDate: 'Date du taux de change',
    exchangeRateTableNumber: 'Numéro de tableau des taux de change',
    amountsType: 'Type de montant',
    warehouseFrom: 'Entrepôt à partir de',
    warehouseTo: 'Entrepôt à',
    signature: 'Signatures',
    hidden: 'Invisible',
    taxRate: 'Montant de la taxe',
    basicItem: 'Élément basique',
    forServices: 'Pour les prestations',
    reverseChange: 'Changement inverse',
    vatAmount: 'Montant de la caution',
    netWeight: 'Poids net',
    bruttoWeight: 'Poids brut',
    vatRate: 'Taux de TVA',
    visible: 'Visible',
    noFilters: 'Aucun filtre',
    male: 'Homme',
    female: 'Femme',
    financialPeriod: 'Période financières',
    interaction: 'Interaction',
    processName: 'Nom du processus',
    result: 'Résultat',
    image: 'Image',
    referenceNumber: 'Numéro de référence',
    receipts: 'Reçu',
    scaleReport: 'Rapport sur le poids',
    carPositionReport: 'Rapport de placement de la voiture',
    scaleChangeReport: 'Rapport sur les changements de poids',
    manual: 'Manuellement',
    costs: 'Frais',
    positionDescription: "Description de l'article",
    shipmentAddress: 'Adresse de livraison',
    whoPays: 'Qui paye',
    documents: 'Documents',
    shipmentType: "Type d'expédition",
    packageNumber: 'Numéro de colis',
    role: 'Rôle',
    bidSum: "Montant de l'offre",
    trackTime: 'Temps passé ensemble pour une approche donnée',
    totalTime: 'Passer du temps ensemble',
    time: 'Temps',
    hours: 'Heures',
    minutes: 'Minutes',
    responsibility: 'Responsabilité',
    unitOfMeasure: 'Unité de mesure',
    reports: 'Rapports',
    nameFull: 'Nom et prénom',
    addressesPhones: 'Adresses et téléphones',
    productionOrder: "L'ordre de fabrication",
    saleDate: 'Date de vente',
    lodging: 'Hébergement',
    deleteConfirmation: "Voulez-vous vraiment supprimer l'élément de la base de données?",
    constructionAddress: 'Adresse chantier',
    correspondenceAddress: 'Adresse de correspondance',
    resource: 'Ressource',
    blockSelfServiceInterface: "Bloquer l'interface en libre-service",
    blockUnblockSelfServiceInterface: "Verrouillage/déblocage de l'interface libre-service",
    execution: 'Exécution',
    subject: 'Objet',
    reason: 'Raison',
    internalComment: 'Commentaire interne',
    phoneNumber: 'Numéro de téléphone',
    balancedScale: 'Poids stable',
    unbalancedScale: 'Poids instable',
    useStability: 'Utiliser la stabilité',
    scalesNumber: 'Numéro de balance',
    scalesVersion: 'Version de la balance',
    updatePeriod: 'Période de renouvellement',
    iotGateParameters: 'Paramètres de la passerelle IOT',
    lightTable: 'Tableau lumineux',
    additional: 'Supplémentaire',
    dispositionCon: 'Directives',
    factor: 'Facteur',
    deviation: 'Déviation',
    reclamationRelatedTasks: 'Tâches reliées à la réclamation',
    externalIdShort: 'Identifiant externe',
    protocol: 'Protocole',
    productionQuantity: 'Quantité produite',
    constructionPostCode: 'Code postal du chantier',
    use: 'Utiliser',
    width: 'Largeur',
    imo: 'IMO',
    length: 'Longueur',
    trackModel: 'Modèle de transport',
    daysSinceAdmission: "Jours depuis l'admission",
    birthDate: 'Date de naissance',
    fieldType: 'Type de champ',
    dataType: 'Type de données',
    averagePrice: 'Prix moyen',
    grossAmount: 'Valeur (brute)',
    lineNumber: 'L. p.',
    reclamationNumber: 'Numéro de la réclamation',
    clientName: 'Nom du client',
    telephone: 'Téléphone',
    deliveryStatus: "Statut de l'envoi",
    amount: 'Valeur',
    deliveryAddress: 'Adresse de livraison',
    salesDate: 'Date de vente',
    bankAccount: 'Numéro de compte',
    begin: 'Début',
    ending: 'Fin',
    stop: 'Stop',
    entry: "Date d'entrée",
    plannedEntry: "Date d'entrée prévue",
    route: 'Route',
    counterpartyAddress: "Adresse de l'entrepreneur",
    realizationDate: 'Date de réalisation',
    maxLength: 'Longueur maximale',
    disabled: 'Lecture seule',
    login: 'Login',
    passwordConfirmation: 'Confirmation du mot de passe',
    fullRights: 'Autorisations complètes',
    use2FA: 'Authentification à deux facteurs',
    externalUser: 'Utilisateur externe',
    useCustomerAccess: "Utiliser la restriction d'accès par client",
    useForExternalDrivers: 'Pénalité pour le chauffeur extérieur',
    useForInternalDrivers: 'Pénalité pour le chauffeur interne',
    shortName: 'Nom abrégé',
    accountingName: 'Nom pour la comptabilité',
    isPaid: 'Payé',
    isCanceled: 'Annulé',
    isHandcrafted: 'Fait à la main',
    isPrint: 'Imprimé',
    punishmentType: 'Type de frais de stationnement',
    periodType: 'Type de période',
    showYTotal: 'Résumés verticaux',
    showXTotal: 'Résumés horizontaux',
    ref: 'Référence',
    payment: 'Document de paiement',
    daysExceeded: 'Nombre de jours en dépassement',
    ip: 'IP',
    method: 'Méthode',
    url: 'URL',
    query: 'Demande',
    params: 'Paramètre',
    body: 'Corps',
    headers: 'En-têtes',
    waitingTime: "Délai d'attente",
    payedAdvance: 'Acompte versé',
    skonto: 'Escompte',
    sortBy: 'Trier par',
    fakturaCheck: 'Facture',
    fakturaName: 'Nom',
    fakturaNIP: "Numéro d'identification fiscale",
    street: 'Rue',
    locality: 'Ville',
    multi: 'Nombreux',
    executionDays: "Jours d'exécution",
    executionHours: "Heures d'exécution",
    executionMinutes: "Minutes d'exécution",
    currentDateExecution: 'Faites-le en une journée',
    script: 'Scénario',
    trafficLight: 'Feux de signalisation',
    useTrafficLight: 'Utilisez les feux de signalisation',
    connection: 'Connexion',
    okTrafficLight: 'Ok',
    plusTrafficLight: 'Trop loin',
    minusTrafficLight: 'Trop près',
    euMember: "Membre de l'UE",
    allowChangeExecutor: 'Autoriser le changement de sous-traitant',
    allowChangeStage: "Autoriser le changement d'étape",
    stage: 'Etape',
    departureDate: 'Date de départ',
    vehicleType: 'Type de véhicules',
    transportWay: 'Type de transport',
    onlySelected: 'Uniquement sélectionné',
    productVariant: 'Variante de produit',
    recorder: 'Enregistreur',
  },
  report: {
    groupLvls: 'Niveaux de regroupement',
    variants: 'Variantes de rapport',
    writeVariant: 'Enregistrer la variante',
    onlyForThisUser: 'Uniquement pour cet utilisateur',
    newVariant: 'Une nouvelle variante du rapport',
    changeVariantName: 'Renommer la variante de rapport',
    deleteVariant: 'Supprimer une variante de rapport',
    selectedFields: 'Champs sélectionnés',
    columnName: 'Nom de la colonne',
    filtered: 'Filtrable',
    grouped: 'Groupable',
    functional: 'Fonctionnel',
    functions: {
      sum: 'Somme',
      min: 'Min',
      max: 'Max',
      average: 'Moyen',
    },
    total: 'Totale',
    filters: 'Filtres',
    groups: 'Groupes',
    resourses: 'Addition',
    msg: {
      periodControl: 'Le début de la période ne peut pas être supérieur à la fin',
    },
    sorts: 'Triage',
    funcs: 'Calculs',
  },
  email: {
    imapHost: 'Serveur IMAP',
    smtpHost: 'Serveur SMTP',
    imapPort: 'Port IMAP',
    smtpPort: 'Port SMTP',
    imapTls: 'Utiliser SSL/TLS',
    smtpTls: 'Utiliser SSL/TLS',
    forReceive: 'Pour réception',
    forSend: 'Pour envoie',
    storeReceived: 'Enregistrer le message reçu',
    storeSended: 'Enregistrer le message envoyé',
    attachments: 'Pièces jointes',
    newMessage: 'Nouveau message',
    inbox: 'Reçu',
    sent: 'Envoyé',
    toSend: "Boîte d'envoi",
    drafts: 'Brouillons',
    spam: 'Spam',
    trash: 'Corbeille',
    title: 'Message E-mail',
    writeSomething: 'Écrivez quelque chose..',
    contents: 'Contenu',
    from: 'De',
    to: 'à',
    cc: 'CC',
    bcc: 'CCI',
    subject: 'Objet',
    subjectPh: 'Objet du message',
    body: 'Message',
    bodyPh: 'Contenu du message..',
    signatures: 'Signatures',
    response: 'Répondre',
    responseAll: 'Répondre à tous',
    onLineMode: 'Mode en ligne',
    msg: {
      selectEmailFirst: 'Aucun mail sélectionné!',
    },
    all: 'Toutes',
    none: 'Aucune',
    seen: 'Lu',
    unseen: 'Non lu',
    withLinks: 'Avec liens',
    withoutLinks: 'Sans liens',
    processed: 'Développé',
    notProcessed: 'Non développé',
    setAs: 'Marquer comme',
    create: {
      newInteraction: 'Une nouvelle interaction',
      newReclamation: 'Une nouvelle réclamation',
      newCustomerRequest: "Nouvelle demande d'offre",
      customerRequestCorrection: "Correction de la demande d'offre",
    },
    attachTo: {
      interaction: 'Interaction',
      reclamation: 'Réclamation',
      customerRequest: "Demande d'offre",
    },
    noEmails: 'Aucun mail',
    shown: 'Mail lu',
    labels: 'Étiquettes',
    moveToJunk: 'Déplacer vers les spams',
    moveToTrash: 'Déplacer vers la corbeille',
    moveTo: 'Déplacer vers...',
    setLabel: 'Marquer avec une étiquette',
    manageLabels: 'Gérez les étiquettes',
    setMailSignature: 'Créez votre propre signature',
    manageTemplates: 'Gérer les modèles',
    selectEmailAccount: 'Sélectionnez un compte de mail',
    emailMoved: 'Les messages sélectionnés ont été déplacés!',
    fillCounterparties: 'Remplissez les entrepreneurs / personnes de contact dans le mail',
    fillCounterparty: 'Remplissez les entrepreneurs / personnes de contact',
    defaultEmailAccount: 'Compte mail par défaut',
    fillConterpartiesBegin: 'Le système a commencé à remplir des sous-traitants (personnes de contact) à envoyer par mail',
    fillConterpartiesEnd: 'Le système a fini de remplir les sous-traitants (personnes de contact) à envoyer par mail',
    message: 'Message',
    email: 'Email',
    otherFiles: 'Autres fichiers',
    emailSended: 'Email envoyé',
    other: 'Le restant',
    invitedBy: 'Vous a invité',
    youOrganizer: "Vous êtes l'organisateur",
    recievedEmail: 'Email reçu : ',
    productionFile: 'Fichier de production',
    signed: 'Signé',
    signedByClient: 'Signé par le client',
    userSignature: "Signature de l'utilisateur",
    sentEmail: 'Email envoyé: ',
  },
  configurator: {
    selectedParameters: 'Paramètres sélectionnés',
    byNumber: 'selon numéro...',
    byName: 'selon nom / description...',
  },
  settings: {
    title: 'Paramètres',
    lsbThemeDefault: 'Défaut',
    lsbThemeLight: 'Clair',
    lsbThemeDark: 'Sombre',
    lsbTypeFixed: 'Plein',
    lsbTypeCondensed: 'Enfilée',
    lsbTypeScrollable: 'Défilable',
  },
  businessProcess: {
    definitionsListTitle: "Processus d'affaires",
    definitionsFormTitle: 'Modification du diagramme de processus',
  },
  editTypes: {
    created: 'Ajoutée',
    edited: 'Modifié',
    markedToDeleteRestore: 'Marqué pour suppression / Restauré',
    deleted: 'Supprimé',
  },
  tagsView: {
    refresh: 'Rafraîchir',
    close: 'Fermer',
    closeOthers: 'Fermer les autres',
    closeAll: 'Ferme tout',
  },
  navigation: {
    newSubsystem: 'Nouveau sous-système',
    editSubsystem: 'Modification du sous-système',
    newPartition: 'Nouveau chapitre',
    newRoute: 'Une nouvelle vue',
    editRoute: 'Modifier la vue',
    getPrezentation: 'Télécharger la présentation depuis le référentiel',
  },
  documentation: {},
  permission: {},
  guide: {},
  components: {
    historyOfChanges: "L'historique des modifications",
    versionDetails: 'Détails de la version',
    versionsCompare: 'Comparatif des versions',
    relatedTasks: 'Tâches associées',
    linkedDocs: 'Documents associés',
    subordination: 'Structure de subordination',
    advancedSearch: 'Recherche Avancée',
    viewSettings: "Paramètres d'affichage",
    periodManager: 'Réglage de la période',
  },
  example: {},
  errorLog: {
    qrCodeNotFound: 'QR code introuvable',
  },
  excel: {},
  zip: {},
  pdf: {},
  ships: {
    imo: 'Organisation maritime internationale',
  },
  theme: {
    leftSidebar: 'Barre gauche',
  },
  fm: {
    components: {
      fields: {
        input: 'Input',
        button: 'Button',
        textarea: 'Textarea',
        number: 'Number',
        radio: 'Radio',
        checkbox: 'Checkbox',
        time: 'Time',
        date: 'Date',
        rate: 'Rate',
        color: 'Color',
        select: 'Select',
        switch: 'Switch',
        slider: 'Slider',
        text: 'Text',
        blank: 'Custom',
        fileupload: 'File',
        imgupload: 'Image',
        editor: 'Editor',
        cascader: 'Cascader',
        table: 'Sub-table',
        'view-list': 'Liste',
        pagination: 'Mise en page',
        grid: 'Grid',
        card: 'Card',
        tabs: 'Tabs',
        divider: 'Divider',
        toolbar: 'Toolbar',
        container: 'Container',
        btnGroup: 'Btn group',
        collapse: 'Collapse',
        dropdown: 'Dropdown',
        inputGroup: 'Input group',
      },
      basic: {
        title: 'Basic Component',
      },
      advance: {
        title: 'Advance Component',
      },
      layout: {
        title: 'Layout',
      },
    },
    description: {
      containerEmpty: 'You can drag and drop the item from the left to add components',
      configEmpty: 'Please add a component',
      tableEmpty: 'You can drag and drop the item from the left to add components',
      uploadJsonInfo: 'There is the format of JSON below, you can overwrite it with you own JSON code',
    },
    message: {
      copySuccess: 'Copie réussie',
      validError: 'La validation des données du formulaire a échoué',
      restoreItem: "L'objet sera restauré !",
      deleteItem: "L'élément sera marqué pour suppression!",
      topicEmpty: 'Le sujet ne peut pas être vide!!',
      reportOptionGetError: 'Erreur lors de la réception des paramètres du rapport',
      recipientEmpty: 'Destinataire non spécifié !!',
    },
    actions: {
      import: 'Import JSON',
      clear: 'Clear',
      preview: 'Preview',
      json: 'Generate JSON',
      code: 'Generate Code',
      getData: 'Get Data',
      reset: 'Reset',
      copyData: 'Copy Data',
      cancel: 'Cancel',
      confirm: 'Confirm',
      addOption: 'Add Option',
      addColumn: 'Ajouter une colonne',
      addItem: 'Ajouter une position',
      addTab: 'Add Tab',
      upload: 'Upload',
      add: 'Add',
      addCorrespondence: 'Ajouter une correspondance',
    },
    config: {
      form: {
        title: 'Formulaire',
        actions: 'Actions',
        functionNaem: 'Nom de la fonction',
        functionDescription: 'Description de la fonction',
        dataSource: 'Source de données',
        labelPosition: {
          title: "Emplacement de l'étiquette",
          left: 'Gauche',
          right: 'Droite',
          top: 'Centre',
        },
        labelWidth: 'Label Width',
        size: 'Size',
        customClass: 'Custom Class',
        methods: 'Fonctions',
        toolbarContent: 'Toolbar content',
      },
      widget: {
        tabTitle: 'Atributs',
        title: 'Titre',
        subTitle: 'Sous-titre',
        model: 'Model',
        name: 'Name',
        width: 'Width',
        height: 'Height',
        size: 'Size',
        labelWidth: 'Label Width',
        custom: 'Custom',
        placeholder: 'Placeholder',
        layout: 'Layout',
        block: 'Block',
        inline: 'Inline',
        contentPosition: 'Content Position',
        left: 'Left',
        right: 'Right',
        center: 'Center',
        showInput: 'Display Input Box',
        min: 'Minimum',
        max: 'Maximum',
        step: 'Step',
        multiple: 'Multiple',
        filterable: 'Searchable',
        allowHalf: 'Allow Half',
        showAlpha: 'Support transparency options',
        showLabel: 'Show lable',
        option: 'Option',
        staticData: 'Static Data',
        remoteData: 'Remote Date',
        remoteFunc: 'Remote Function',
        value: 'Value',
        label: 'Titre',
        childrenOption: 'Sub-Option',
        defaultValue: 'Default Value',
        showType: 'Display type',
        isRange: 'Range Time',
        isTimestamp: 'Get time stamp',
        startPlaceholder: 'Placeholder of start time',
        endPlaceholder: 'Placeholder of end time',
        format: 'Format',
        uploadLimit: 'Maximum Upload Count',
        perPage: 'Nombre de lignes',
        isQiniu: 'Upload with Qiniu Cloud',
        tokenFunc: 'A funchtin to get Qiniu Uptoken',
        imageAction: 'Picture upload address',
        tip: 'Text Prompt',
        action: 'Upload Address',
        defaultType: 'Data Type',
        string: 'String',
        object: 'Object',
        array: 'Array',
        number: 'Number',
        boolean: 'Boolean',
        search: 'Search',
        tel: 'Tel',
        date: 'Date',
        time: 'Time',
        range: 'Range',
        color: 'Color',
        integer: 'Integer',
        float: 'Float',
        url: 'URL',
        email: 'E-mail',
        password: 'Password',
        hex: 'Hexadecimal',
        noGutter: "Désactiver l'espacement de la grille",
        columnOption: 'Paramétrage de la colonne',
        itemsOption: 'Réglage de la liste',
        cols: 'cols',
        rowCols: 'Colonne de ligne',
        rowColsSm: 'Colonne de ligne (Sm)',
        rowColsMd: 'Colonne de ligne (Md)',
        rowColsLg: 'Colonne de ligne (Lg)',
        rowColsXl: 'Colonne de ligne (Xl)',
        offset: 'offset',
        order: 'order',
        sm: 'sm',
        md: 'md',
        lg: 'lg',
        xl: 'xl',
        justify: 'Horizontal Arrangement',
        justifyStart: 'Start',
        justifyEnd: 'End',
        justifyCenter: 'Center',
        justifySpaceAround: 'Space Around',
        justifySpaceBetween: 'Space Between',
        type: 'Type',
        default: 'Default',
        card: 'Tabs',
        borderCard: 'Border-Card',
        tabPosition: 'Tab Position',
        top: 'Top',
        bottom: 'Bottom',
        tabOption: 'Label Configuration',
        tabName: 'Tab Name',
        customClass: 'Styles par défaut',
        bodyClass: 't-body style',
        attribute: 'Attribute Action',
        dataBind: 'Data Binding',
        hidden: 'Hidden',
        readonly: 'Read Only',
        disabled: 'Disabled',
        editable: 'Text box is editable',
        clearable: 'Display Clear Button',
        arrowControl: 'Use the arrow for time selection',
        isDelete: 'Deletable',
        isEdit: 'Editable',
        showPassword: 'Display Password',
        validate: 'Validation',
        required: 'Required',
        patternPlaceholder: 'Fill in the regular expressions',
        newOption: 'New Option',
        tab: 'Tab',
        validatorRequired: 'Required',
        validatorType: 'Invaild format',
        validatorPattern: 'Unmatched pattern',
        showWordLimit: 'Show word limit',
        maxlength: 'Max length',
        hover: 'Rétroéclairage sélectionné',
        striped: 'À rayures',
        selectable: 'Sélectionnable',
        selectMode: 'Mode de sélection',
        fields: 'Colonnes',
        sortBy: 'Trier par',
        alignSelf: 'Alignez-vous',
        alignContent: 'Alignement du contenu',
        align: 'Alignement',
        alignH: 'Alignement horizontal',
        alignV: 'Alignement vertical',
        alignTop: 'Top',
        alignMiddle: 'Middle',
        alignBottom: 'Bottom',
        alignAround: 'Around',
        alignBetween: 'Between',
        alignStart: 'Start',
        alignCenter: 'Center',
        alignEnd: 'End',
        alignBaseline: 'Baseline',
        alignStretch: 'Stretch',
        tag: 'Tag',
        fluid: 'Remplissage',
        keyNav: 'Naviguer au clavier',
        variant: 'Variante',
        variants: {
          primary: 'Primary',
          secondary: 'Secondary',
          success: 'Success',
          danger: 'Danger',
          warning: 'Warning',
          info: 'Info',
          light: 'Light',
          dark: 'Dark',
          outlinePrimary: 'Outline primary',
          outlineSecondary: 'Outline secondary',
          outlineSuccess: 'Outline success',
          outlineDanger: 'Outline danger',
          outlineWarning: 'Outline warning',
          outlineInfo: 'Outline info',
          outlineLight: 'Outline light',
          outlineDark: 'Outline dark',
        },
        pill: 'Pill',
        squared: 'Squared',
        vertical: 'Vertical',
        accordion: 'Accordion',
        appear: 'Appear',
        visible: 'Visible',
        text: 'Text',
        dropleft: 'Drop left',
        dropright: 'Drop right',
        dropup: 'Drop up',
        noCaret: 'No caret',
        noFlip: 'Pas de retour',
        split: 'Diviser',
      },
    },
    upload: {
      preview: 'Aperçu',
      edit: 'Remplacer',
      delete: 'Effacer',
    },
  },
  project: {
    completed: 'Terminé',
    inProgress: 'En cours',
    behind: 'Retardé',
    taskOverview: 'Aperçu des tâches',
    projectStatus: 'Statut du projet',
    weeklyReport: 'Bilan hebdomadaire',
    monthlyReport: 'Bilan mensuel',
    action: 'Action',
    settings: 'Paramètres',
    tasks: 'Tâches',
    recentActivity: 'Dernière activité',
    completedTasksOf: 'Tâches réalisées avec',
    status: 'Statut',
    assignedTo: 'Assigné à',
    totalTimeSpent: 'Całkowity przypisany czas',
  },
}
